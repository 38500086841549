import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ReportService, DatasourcePivotTableService, AlertService, AuthenticationService , DatasourceLookupService } from '@/services/';
import {  HttpClient} from "@angular/common/http";
import { DxDateBoxComponent, DxSelectBoxComponent, DxLookupComponent, DxPivotGridComponent} from "devextreme-angular";
import { saveAs } from 'file-saver';
import DataSource from "devextreme/data/custom_store";
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { User, Organisation} from '@/models';
import {DatePipe} from '@angular/common';

import {  Subject, Subscription} from "rxjs";
import {  takeUntil} from "rxjs/operators";
import dxCheckBox from 'devextreme/ui/check_box';

@Component({
  selector: 'report-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  Year: number[] = new Array();
  Month: number[] =  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  Quarters: number[] =  [1, 2, 3, 4];
  ThisMonth: number =  (new Date()).getMonth();
  ThisQuarter: number = this.getQuarter();
  ThisYear: number;
  platformStores: any = {};
  destroyed: Subject <void> ;
  loadIndicatorVisible: boolean = false;
  currentUserSubscription: Subscription;
  currentUser: User;
  currentOrganisationOid : number = 0;
  dataSource: any = {};
  reportsKinds: any = {};
  fileName: string;
  DisableMonth: boolean = true;

  @ViewChild("startdate", {static: false}) orderStartdate: DxDateBoxComponent;
  @ViewChild("enddate", {static: false}) orderEnddate: DxDateBoxComponent;

  @ViewChild("stockstartdate", {static: false}) stockStartdate: DxDateBoxComponent;
  @ViewChild("stockenddate", {static: false}) stockEnddate: DxDateBoxComponent;

  @ViewChild("administrationMonth", {static: false}) administrationMonth: DxSelectBoxComponent;
  @ViewChild("administrationYear", {static: false}) administrationYear: DxSelectBoxComponent;
  @ViewChild("administrationPlatfom", {static: false}) administrationPlatfom: DxLookupComponent;

  @ViewChild("kaffeestauerMonth", {static: false}) kaffeestauerMonth: DxSelectBoxComponent;
  @ViewChild("kaffeestauerYear", {static: false}) kaffeestauerYear: DxSelectBoxComponent;

  @ViewChild("pivotMonth", {static: false}) pivotMonth: DxSelectBoxComponent;
  @ViewChild("pivotQuarter", {static: false}) pivotQuarter: DxSelectBoxComponent;
  @ViewChild("pivotYear", {static: false}) pivotYear: DxSelectBoxComponent;
  @ViewChild("pivotskind", {static: false}) pivotskind: DxSelectBoxComponent;
  @ViewChild("pivotTable", {static: false}) pivotTable: DxPivotGridComponent;
  @ViewChild("pivotChkMonth", {static: false}) pivotChkMonth: dxCheckBox;



  constructor(
    private alert: AlertService,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private reportService: ReportService,
    private authenticationService: AuthenticationService,
    private datasourceLookupService: DatasourceLookupService,
    private datasourcePivotTableService: DatasourcePivotTableService
  ) {
    this.destroyed = new Subject < void > ();
   }

  ngOnInit() {
    this.authenticationService.getOrganisationObservable()
    .pipe(takeUntil(this.destroyed))
    .subscribe(org => {
        this.currentOrganisationOid = parseInt(org);

        this.reportsKinds = [
          {
            ID: 1,
            name: 'Alle landen'
          },{
            ID: 2,
            name: 'Landen binnen de EU'
          },{
            ID: 3,
            name: 'Landen buiten de EU'
          },{
            ID: 4,
            name: 'BTW 0 percentage'
          }
        ];

   //   this.platformStores = this.datasourceLookupService.getDatasource("Country");
    })
//    this.platformStores = this.datasourceLookupService.getDatasource("Country");
    this.fillYears();
    this.currentUserSubscription = this.authenticationService.currentUser
    .pipe(takeUntil(this.destroyed))
    .subscribe(user => {
      this.currentUser = user;
    });
  }

  ngAfterViewChecked() {
        this.pivotMonth.disabled = this.DisableMonth;
        this.pivotQuarter.disabled = !this.DisableMonth;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed.next();
    this.destroyed.complete();
  }

  GenerateOrdersReport(){
    //this.loadIndicatorVisible = true;
    this.reportService.downloadOrderList(new Date(this.orderStartdate.value), new Date(this.orderEnddate.value)).subscribe(
    (response) => {

        var blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, "Orderlist" + this.datePipe.transform(this.orderStartdate.value,"yyyy-MM-dd") + '.pdf');
      //  this.loadIndicatorVisible = false;
    },
    e => {
          console.log(e);
     //     this.loadIndicatorVisible = false;
    });
  }

  LoadPivotData(){
    var quodater = 0;
    if (this.DisableMonth == true){
      quodater = this.pivotQuarter.value;
    }
    console.log(quodater);
    var newdate = new Date(this.pivotYear.value, this.pivotMonth.value -1, 1);
	    this.dataSource = {
          fields: [{
              caption: 'Land',
              width: 120,
              dataField: 'ShippingCountry',
              area: 'row',
              expanded: true
          }, {
              caption: 'BTW percentage',
              dataField: 'ShippingCostVatPercentage',
              width: 150,
              area: 'row',
              expanded: false
            }, {
              caption: 'Factuurnummer',
              dataField: 'InvoiceNumber',
              width: 150,
              area: 'row',
          }, {
              caption: 'Totaal excl. BTW',
              dataField: 'TotalPriceExclVat',
              dataType: 'number',
              format: '€ #.##0,##',
              summaryType: 'sum',
              area: 'data'
          }, {
            caption: 'Totaal BTW',
            dataField: 'TotalVat',
            dataType: 'number',
            format: '€ #.##0,##',
            summaryType: 'sum',
            area: 'data'
        }, {
          caption: 'Totaal incl. BTW',
          dataField: 'TotalPriceInclVat',
          dataType: 'number',
          format: '€ #.##0,##',
          summaryType: 'sum',
          area: 'data'
      }, {
        caption: 'BTW nummer',
        dataField: 'VatNumber',
        dataType: 'string',
        isMeasure: 'false',
        summaryType: 'max',
        area: 'data'
    }],
          store: this.datasourcePivotTableService.getDatasource(newdate, this.pivotskind.value, quodater)}
          this.fileName = this.datePipe.transform(newdate,"yyyy-MM") + " EU rapportage";
  }

  GenerateStockReport(){
    //this.loadIndicatorVisible = true;
    this.reportService.downloadStockList(new Date(this.stockStartdate.value), new Date(this.stockEnddate.value)).subscribe(
    (response) => {

      var blob = new Blob([response], { type: 'application/xlsx' });
        saveAs(blob, "Voorraadlijst" + this.datePipe.transform(this.stockStartdate.value,"yyyy-MM-dd") + '.xlsx');
      //  this.loadIndicatorVisible = false;
    },
    e => {
          console.log(e);
     //     this.loadIndicatorVisible = false;
    });
  }

  GenerateAdministrationReport(){
    //this.loadIndicatorVisible = true;
    var newdate = new Date(this.administrationYear.value, this.administrationMonth.value -1, 1);
    this.reportService.downloadAdministration(newdate).subscribe(
    (response) => {
        var blob = new Blob([response], { type: 'application/xlsx' });
        saveAs(blob, "Administration" + this.datePipe.transform(newdate,"yyyy-MM") + '.xlsx');
      //  this.loadIndicatorVisible = false;
    },
    e => {
          console.log(e);
     //     this.loadIndicatorVisible = false;
    });
  }

  GenerateCommissieReport(){
        //this.loadIndicatorVisible = true;
        var newdate = new Date(this.administrationYear.value, this.administrationMonth.value -1, 1);
        this.reportService.downloadCommissieReport(newdate).subscribe(
        (response) => {
            var blob = new Blob([response], { type: 'application/xlsx' });
            saveAs(blob, "Commissie" + this.datePipe.transform(newdate,"yyyy-MM") + '.xlsx');
          //  this.loadIndicatorVisible = false;
        },
        e => {
              console.log(e);
         //     this.loadIndicatorVisible = false;
        });
  }

  GenerateFeeReport(){
    //this.loadIndicatorVisible = true;
    var newdate = new Date(this.kaffeestauerYear.value, this.kaffeestauerMonth.value -1, 1);
    this.reportService.downloadFee(newdate).subscribe(
    (response) => {
        var blob = new Blob([response], { type: 'application/xlsx' });
        saveAs(blob, "Fee" + this.datePipe.transform(newdate,"yyyy-MM") + '.xlsx');
      //  this.loadIndicatorVisible = false;
    },
    e => {
          console.log(e);
     //     this.loadIndicatorVisible = false;
    });
  }

  getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3);
  }

  fillYears()
  {
      var startYear = 2015;
      var endYear = (new Date()).getFullYear();

      for (var i = startYear; i <= endYear; i++) {
        this.Year.push(i);
      }

      this.ThisYear = this.Year.findIndex(year => year === endYear);
  }

  chkDisableMonthChanged(e = null){
    if (e.value == true)
    {
      this.pivotMonth.disabled = true;
      this.pivotQuarter.disabled = false;
      this.DisableMonth = true;
    }else{
      this.pivotMonth.disabled = false;
      this.pivotQuarter.disabled = true;
      this.DisableMonth = false;
    }
  }

}
