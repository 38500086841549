import { IFee} from '@/interfaces'

export class Fee implements IFee {

  constructor() {}

  Oid: number;
  Name: string;
  Value: number;
  CalculationId: number;
  OrganizationId: number;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
}