import { EventEmitter, Inject, Injectable, PLATFORM_ID   } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User, Organisation, Roles } from '@/models';
import { Token } from '@angular/compiler';
import { AlertService} from '@/services/alert.service';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import {TranslateService} from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentOrganisation: BehaviorSubject<string>;

    authKey: string = "wsAuth";
    clientId: string = "wsConnect";

    constructor(private http: HttpClient, private translate: TranslateService, private alert: AlertService, @Inject(PLATFORM_ID) private platformId: any) {
        this.currentUserSubject = new BehaviorSubject<User>(this.getAuth());
        this.currentOrganisation = new BehaviorSubject<string>(null);
    }

    public get currentUser(): Observable<User> {
        return this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.getValue();
    }

    // performs the login
    login(username: string, password: string): Observable<boolean> {
        var url = environment.apiUrl + 'Token/Auth';
        var data = {
            Username: username,
            Password: password,
            ClientId: this.clientId,
            // required when signing up with username/password
            GrantType: "password",
            // space-separated list of scopes for which the token is issued
            Scope: "offline_access profile email"
        };

        return this.http
        .post<User>(url, data)
        .pipe(
            map(res => {
                let token = res && res.Token;
                // if the token is there, login has been successful
                if (token) {
                    // store username and jwt token
                    this.setAuth(res);
                    this.currentUserSubject.next(res);
                    // successful login
                    return true;
                }
                // failed login
                return false;
            })
        );
    }


    hasPermission(Role: Roles) {
        // no user hide
        const currentUser = this.currentUserValue;
        if (currentUser == null) return false;
        
        //administroe can view eventhing
        if (currentUser.RoleName.toUpperCase() == "ADMINISTRATOR") return true;   
        return currentUser.RoleName.toUpperCase() == Role ?  true : false;
    }
            
    // performs the logout
    logout(): boolean {
        this.setAuth(null);
        this.currentUserSubject.next(null);
        return true;
    }

    // Persist auth into localStorage or removes it if a NULL argument is given
    setAuth(auth: User | null): boolean {
        if (isPlatformBrowser(this.platformId)) {
            if (auth) {
                localStorage.setItem(
                    this.authKey, 
                    JSON.stringify(auth));                    
            }
            else {
                localStorage.removeItem(this.authKey);
            }
        }
        return true;
    }

    // Retrieves the auth JSON object (or NULL if none)
    getAuth(): User | null {
        if (isPlatformBrowser(this.platformId)) {
            var i = localStorage.getItem(this.authKey);
            if (i) {
                return JSON.parse(i);
            }
        }
        return null;
    }

    // Returns TRUE if the user is logged in, FALSE otherwise.
    isLoggedIn(): Observable<boolean> {
        return this.currentUser
            .pipe(map(u => u != null));
    }

    public GetOrganisations() //Subject
    {
        var url = environment.apiUrl + 'organization/GetList';
        return this.http.get<Organisation[]>(url)
        .pipe(catchError((res: HttpErrorResponse ) => {
            if (res.status != 401) {
            var message = _('authenticationService.failtRetreved');
            this.translate.get(message).subscribe(res => { this.alert.error(res) });
            //this.alert.error("Failt to retreved organisations");
            return of([]);
            }
        }));
    }

    public getOrganisationObservable(): Observable<string> {
        return this.currentOrganisation.asObservable();
    }

    public getCurrentOrganisation(): string {
        return this.currentOrganisation.value;
    }

    public SetOrganisationHeaderOid(oid: string) //Subject
    {
        this.currentOrganisation.next(oid);
    }
}