import { Component, OnInit, OnDestroy  } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {} from 'devextreme-angular';
import {TranslateService} from '@ngx-translate/core';
import { User } from '@/models';
import { UserService, AuthenticationService } from '@/services';
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import { environment } from '@environments/environment';
import { PlatformaccountStatusComponent } from '@/components/'

@Component({ templateUrl: './home.component.html' })
export class HomeComponent implements OnInit, OnDestroy {

    currentUser: User;
    currentUserSubscription: Subscription;
    users: User[] = [];
    hubConnection: HubConnection;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private translate: TranslateService
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    useLanguage(language: string) {
        this.translate.use(language);
    }

    ngOnInit() {
       // this.loadAllUsers();

       this.authenticationService.currentUser.subscribe((user) => {
           if(user == null && this.hubConnection != null) {
                this.hubConnection.off("update");   
                this.hubConnection.stop();
                this.hubConnection = null;
           }

           if(user != null && this.hubConnection == null) {
                this.hubConnection = new HubConnectionBuilder().withUrl(environment.orderHubUrl, {
                    accessTokenFactory: () => user.Token
                }).build();
        
                this.hubConnection.on("update", (data: any) => {
                    console.log(data);
                });

                this.hubConnection.start().then(() => {
                    this.authenticationService.getOrganisationObservable().subscribe((c) => {
                        if(c == null){
                            return; // Can't subscribe to company null
                        }
                        this.hubConnection.invoke("WatchCompany", c);
                    });
                });
           }
       })
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.currentUserSubscription.unsubscribe();
        this.hubConnection.off("update");
        this.hubConnection.stop();
    }

    deleteUser(id: number) {
        this.userService.delete(id).pipe(first()).subscribe(() => {
            this.loadAllUsers();
        });
    }

    private loadAllUsers() {
        this.userService.getAll().pipe(first()).subscribe(users => {
            this.users = users;
        });
    }
}