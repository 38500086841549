import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './shared/components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AlertComponent } from '@/components';
import {DatePipe} from '@angular/common';
import { SideNavInnerToolbarModule} from './layouts/side-nav-inner-toolbar/side-nav-inner-toolbar.component'
import { AuthenticationInjector } from './services/authentication.interceptor';
import { DxToastModule, DxButtonModule, DxTextBoxModule } from 'devextreme-angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    SideNavInnerToolbarModule,
    AppRoutingModule,
    FooterModule,
    FontAwesomeModule,
    HttpClientModule,    
    BrowserAnimationsModule,
    FormsModule,
    DxToastModule,
    DxButtonModule,
    DxTextBoxModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
    
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInjector, multi: true}
  ]

})


export class AppModule {
  
 }
 // required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
