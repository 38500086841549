import { ICarrierSelection} from '@/interfaces'

export class CarrierSelection implements ICarrierSelection {

  constructor() {}

  Oid: number;
  Name: string;
  AccountNumber: string;
  SenderReference: string;
  ProductName: string;
  CarrierCode: string;
  DefaultCarrier: boolean;
  OrganizationId: number;
  CarrierId: number;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
  PrintLabel: boolean;
  }