import { IEmailKind} from '@/interfaces'

export class EmailKind implements IEmailKind {

  constructor() {}

  Oid: number;
  Name: string;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
  }