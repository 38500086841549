// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://wsconnectapi.azurewebsites.net/api/',
  orderHubUrl: 'https://wsconnectapi.azurewebsites.net/LiveUpdateSignalRHub',
  ebay_client_id: 'AVIct-AenV-PRD-1bc40d285-0bf6b89b',
  ebay_response_type: 'code',
  ebay_redirect_uri: 'A_V_Ict-AVIct-AenV-PRD--lxompwr',
  ebay_scope: 'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
  amazon_redirect_uri: 'https://wsconnect.eu/Platformsaccounts'
};

/*
export const environment = {
  production: false,
  //apiUrl: 'https://wsconnectapi.azurewebsites.net/api/',
  apiUrl: 'https://localhost:44348/api/',
  //orderHubUrl: 'https://wsconnectapi.azurewebsites.net/LiveUpdateSignalRHub',
  orderHubUrl: 'https://localhost:44348/LiveUpdateSignalRHub',
  ebay_client_id: 'AVIct-AenV-PRD-1bc40d285-0bf6b89b',
  ebay_response_type: 'code',
  ebay_redirect_uri: 'A_V_Ict-AVIct-AenV-PRD--lxompwr',
  ebay_scope: 'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
  amazon_redirect_uri: 'https://localhost:4200/Platformsaccounts'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
