import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient } from "@angular/common/http";
import { AlertService} from '@/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DatasourceLookupService {

  constructor(private httpClient: HttpClient, private alert: AlertService, private translate: TranslateService) {}

  public getDatasource(url: string) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== "";
    }
  const apiUrl = environment.apiUrl + url + "/GetList";
  return new CustomStore({
    useDefaultSearch: true,
    key: "Oid",
    loadMode: "raw",
    load: () => {
        return this.httpClient.get(apiUrl)
            .toPromise();
    }
    });
  }
}