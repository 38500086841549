import { IExceptionWords} from '@/interfaces'

export class ExceptionWords implements IExceptionWords {

  constructor() {}

  Oid: number;
  Name: string;
  OrganizationOid: number;
  CalculationOid: number;
  PlatformAccountOid: number;
  CreateDate: Date;
  LastModifiedDate: Date;
  }