import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

export const navigation = [
    {
        text: _('menu.home'),
        path: '/home',
        icon: 'far fa-home',
        anonymous: true
    }, {
        text: _('menu.products'),
        path: '/Products',
        icon: 'fas fa-box-full',
        roles: ['Administrator','OrganizationAdmin', 'OrganizationUser'],
        anonymous: false
    },
    {
        text: _('menu.order'),
        path: '/Orders',
        icon: 'far fa-euro-sign',            
        roles: ['Administrator','OrganizationAdmin', 'OrganizationUser'],
        anonymous: false    
    },     {
        text: _('menu.reports'),
        path: '/Reports',
        icon: 'far fa-file-chart-line',
        roles: ['Administrator','OrganizationAdmin', 'OrganizationUser'],
        anonymous: false        
    }, 
    {
        text: _('menu.orderdates'),
        path: '/OrderDates',
        icon: 'far fa-euro-sign',
        roles: ['Administrator','OrganizationAdmin', 'OrganizationUser'],
        anonymous: false    
    }, 
    {
        text: _('menu.platformsaccounts'),
        path: 'Platformsaccounts',
        icon: 'far fa-building',
        roles: ['Administrator','OrganizationAdmin','OrganizationUser'],
        anonymous: false
    },
    {
        text: _('menu.setting'),
        icon: 'far fa-cog',
        roles: ['Administrator'],
        anonymous: false,
        items: [{
            text: _('menu.countries'),
            path: '/Countries',
            icon: 'far fa-flag',
            roles: ['Administrator'],
            anonymous: false
        }, {
            text: _('menu.fees'),
            path: '/Fees',
            icon: 'far fa-sack',
            roles: ['Administrator','OrganizationAdmin', 'OrganizationUser'],
            anonymous: false
        }, {
            text: _('menu.exceptionwords'),
            path: '/ExceptionWords',
            icon: 'far fa-sack',
            roles: ['Administrator','OrganizationAdmin'],
            anonymous: false
        },  {
            text: _('menu.language'),
            path: '/Languages',
            icon: 'fas fa-language',
            roles: ['Administrator'],
            anonymous: false
        }, {
            text: _('menu.carrier'),
            path: '/Carrier',
            icon: 'fas fa-truck',
            roles: ['Administrator'],
            anonymous: false
        }, {
            text: _('menu.carrierselection'),
            path: '/Carrierselection',
            icon: 'fas fa-dolly',
            roles: ['Administrator','OrganizationAdmin'],
            anonymous: false
        }, {
            text: _('menu.emailkind'),
            path: '/Emailkind',
            icon: 'fas fa-envelope',
            roles: ['Administrator'],
            anonymous: false
        }, {
            text: _('menu.transactionalemail'),
            path: '/TransactionalEmail',
            icon: 'fas fa-at',
            roles: ['Administrator'],
            anonymous: false
        }, {
            text: _('menu.organisations'),
            path: '/Organisations',
            icon: 'far fa-building',
            roles: ['Administrator'],
            anonymous: false
        }, {
            text: _('menu.platforms'),
            path: '/Platforms',
            icon: 'far fa-building',
            roles: ['Administrator'],
            anonymous: false
        }]
    }];
