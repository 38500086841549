import { NgModule, Component, enableProdMode, Inject, OnDestroy} from "@angular/core";
import {Router} from "@angular/router"
import { HttpClient, HttpClientModule, HttpParams, HttpHeaders} from "@angular/common/http";
import { DxDataGridModule, DxTemplateModule, DxButtonComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { environment } from "@environments/environment";
import { DatasourceDatagridService, DatasourceLookupService, AlertService, AuthenticationService} from "@/services/";
import { PlatformAccount } from "@/models/platformaccount";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthenticationToken } from '@/models'
import {TranslateService} from '@ngx-translate/core';
import { Response } from 'selenium-webdriver/http';

@Component({
  selector: 'platformaccount-datagrid',
  templateUrl: './platformaccount-datagrid.component.html',
  styleUrls: ['./platformaccount-datagrid.component.scss']
})
export class PlatformAccountDatagridComponent {
  destroyed: Subject<void>;
  dataSource: any = {};
  dataSourceOrganisation: any = {};
  dataSourcePlatform: CustomStore = null;
  selectPlatformAccount: PlatformAccount;
  popupVisible = false;
  authenticationCode;
  authenticationExpiresIn;
  authenticationToken: AuthenticationToken;


  constructor(
    private datasourceDatagridService: DatasourceDatagridService, 
    private datasourceLookupService: DatasourceLookupService,
    private alert: AlertService, 
    private authenticationService: AuthenticationService, 
    private http: HttpClient, 
    private router: Router,
    private translate: TranslateService) { 
    this.destroyed = new Subject<void>();
    this.authenticationToken = new AuthenticationToken();
  }

  onEditorPreparing(e:any){
    e.editorOptions.disabled = e.parentType == "dataRow" && e.dataField == "OrganizationOid" && !e.row.inserted;   
  }

  showInfo(platformAccount) {
    
    this.selectPlatformAccount = platformAccount.data;
    this.popupVisible = true;
   
    console.log("Out function");
  }

  authorised() {
 
    var platformName = this.selectPlatformAccount['PlatformName'];
    //TODO
    console.log(platformName);
    if (platformName.includes('Amazon API')) {
      platformName = "Amazon API"
      
    }
    var platformGuideCode = this.selectPlatformAccount['GuidCode'];
      sessionStorage.setItem('PlatformGuideCode', platformGuideCode );
      // ebay link
      switch (platformName) {
        case "Ebay":
            console.log("Ebay");
            sessionStorage.setItem('PlatformName', platformName);
            var sessionCode = sessionStorage.getItem('AuthCode');
            var sessionPlatformGuideCode = sessionStorage.getItem('PlatformGuideCode');
            console.log(sessionCode);
            if (sessionCode == null){
              console.log("open window");
              // URL code must be from environment
             window.open("https://auth.ebay.com/oauth2/authorize?client_id="+environment.ebay_client_id+"&response_type="+environment.ebay_response_type+"&redirect_uri="+environment.ebay_redirect_uri+"&scope=https://api.ebay.com/oauth/api_scope/sell.fulfillment", "_self" );
           
            
            }
            else {
              console.log("Inside else");
              this.authenticationCode = sessionCode;
              this.authenticationToken.PlatformCode =  sessionPlatformGuideCode;
          
            }
            break;
          case 'Amazon API':
           console.log("Amazon API");
           sessionStorage.setItem('PlatformName', platformName);
           var sessionCode = sessionStorage.getItem('AuthCode');
           var sessionPlatformGuideCode = sessionStorage.getItem('PlatformGuideCode');
           console.log(sessionCode);
          if (sessionCode == null){
            console.log("open window");
            
             window.open("https://sellercentral.amazon.de/apps/authorize/consent?application_id=amzn1.sp.solution.743273b5-e644-45d9-8397-41bcb61ddb4a&state="+ platformGuideCode+"&version=beta", "_self");
          }
             break;
             case 'Magento 2':   
              
              sessionStorage.setItem('PlatformName', platformName);
              var sessionCode = sessionStorage.getItem('AuthCode');
              var sessionPlatformGuideCode = sessionStorage.getItem('PlatformGuideCode');
              if (sessionCode == null){
                console.log("open window");
               

                var timestamp = Date.now();
                let MagnetoshopUrl = "https://www.sauterwijnen.nl/oauth/token/request";
      
                //var MagnetoshopUsername = prompt("Username:","admin")
                //var MagnetoshopPassword = prompt("Password:","Entr@nce05")

               
                var nonce = this.randomString(16);
                
                var token =  
                'oauth_consumer_key="0e9rd5voc0bg6gmkrlrcekb2umn8yqav",'+
                'oauth_nonce="'+nonce.toString()+'",'+
                'oauth_signature="tnnArxj06cWHq44gCs1OSKk%2FjLY%3D",'+
                'oauth_signature_method="HMAC-SHA1",'+
                'oauth_timestamp='+timestamp.toString()+','+
                'oauth_version="1.0"'

                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', "text/html; charset=utf-8");
                headers = headers.append('Authorization', "OAuth "+token);

                let options = { headers: headers };             
  
               
  
               this.http.post(MagnetoshopUrl,"",options).subscribe(response => {this.getMagentoAuthCode(response); });

              }
             break;
          default:
              console.log("Platform doesn't have a way of authentication set.")
        }
  }

  randomString(length) {
    var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'
    var result = ''

    while (length > 0) {
        var bytes = new Uint8Array(16);
        var random = window.crypto.getRandomValues(bytes);

        random.forEach(function(c) {
            if (length == 0) {
                return;
            }
            if (c < charset.length) {
                result += charset[c];
                length--;
            }
        });
    }
    return result;
}

  getMagentoAuthCode(response : any)
  {

    console.log(response);
   // window.open(window.location.href+"?code="+response , "_self");
  }
 
  ngOnInit(){

    //takeUntil zorgt ervoor dat ik niet alles onderelkaar moet destroyen
    this.authenticationService.getOrganisationObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        	this.dataSource = this.datasourceDatagridService.getDatasource("PlatformAccount");
      })

    this.dataSourceOrganisation = this.datasourceLookupService.getDatasource("Organization");
    this.dataSourcePlatform = this.datasourceLookupService.getDatasource("Platform");
  }

  ngAfterViewInit()
  {

    var Url = new URL(window.location.href);

    console.log(Url);
    var code = Url.searchParams.get("code");

    if (code != null){
      sessionStorage.setItem('AuthCode', code );
    }
    
    var sessionCode = sessionStorage.getItem('AuthCode');
    var sessionPlatformGuideCode = sessionStorage.getItem('PlatformGuideCode');
    var sessionPlatformName = sessionStorage.getItem('PlatformName');

    if((sessionCode != null && sessionPlatformGuideCode != null)){
      let url = environment.apiUrl+"PlatformAccount/SendToken";
      
      var data = JSON.stringify({
        platformCode: sessionPlatformGuideCode,
        token: sessionCode,
        platformName: sessionPlatformName,
      })

      
        
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
     // alert("send post to: "+url);
     
      this.http.post(url,data,options).subscribe();

      sessionStorage.removeItem('AuthCode');
      sessionStorage.removeItem('PlatformGuideCode');
      sessionStorage.removeItem('PlatformName');
      //this.router.navigate(['/Platformsaccounts']);   
      
    }
  };

  ngOnDestroy(): void {
    window.removeEventListener("message", function(event){} ); 
    //Alles verwijderen van de Observables
    this.destroyed.next();
    this.destroyed.complete();   
  }


}
