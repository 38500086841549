import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { locale, loadMessages } from "devextreme/localization";
import nlMessages from "devextreme/localization/messages/nl.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('nl');
    loadMessages(nlMessages);
    locale(navigator.language);
  }
}
