import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { IAlertmessage } from '@/interfaces'
import { AlertService } from '@/services';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit, OnDestroy {

    isVisible: boolean = false;
    displayTime: number = 1000;
    toastType: string = "info";
    message: string = "";

    private subscription: Subscription;
    alert: IAlertmessage;

    constructor(private alertService: AlertService) { 

    }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(a => { 
            this.displayTime = a.showtime;
            this.toastType = a.type;
            this.message = a.message;
            this.isVisible = true;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}