import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { IPlatformAccount} from '@/interfaces'
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private httpClient: HttpClient, private alert: AlertService) {}
  
     // Uses http.get() to load data from a single API endpoint
     getPlatformAccountStatus() {
        return this.httpClient.get<IPlatformAccount[]>(environment.apiUrl +  'PlatformAccount/GetStatus');
    }
}
