import { ICalculation} from '@/interfaces'

export class Calculation implements ICalculation {

  constructor() {}

  Oid: number;
  Name: string;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
}