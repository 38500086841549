import { Component, OnInit, NgModule, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SideNavigationMenuModule } from '../../shared/components';
import { HeaderModule } from '../../shared/components';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { CommonModule } from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

import { navigation } from '../../app-navigation';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '@/services';

@Component({
    selector: 'app-side-nav-inner-toolbar',
    templateUrl: './side-nav-inner-toolbar.component.html',
    styleUrls: ['./side-nav-inner-toolbar.component.scss']
})
export class SideNavInnerToolbarComponent implements OnInit {

    menuItems = this.translateMenuItems(navigation);
    selectedRoute = '';

    @Input()
    menuOpened: boolean;

    @Input()
    title: string;

    menuMode = 'shrink';
    menuRevealMode = 'expand';
    minMenuSize = 0;
    shaderEnabled = false;

    constructor(private breakpointObserver: BreakpointObserver, private router: Router, private translate: TranslateService, private authService: AuthenticationService) { }

    ngOnInit() {
        this.menuOpened = this.isLargeScreen;

        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.selectedRoute = val.urlAfterRedirects.split('?')[0];
            }
        });

        const translatedItems: {role?: string, roles?: string[], anonymous?: boolean}[] = this.translateMenuItems(navigation);
        this.authService.currentUser.subscribe(user => {
          this.menuItems = translatedItems      
            .filter(i => {
              if(i.anonymous) {
                return true;
              } else if(user == null) {
                return false;
              }
              if(i.roles == null || i.roles == undefined || (user != null && i.roles.includes(user.RoleName))) {
                return true;
              } else {
                return false;
              }         
            });
        });

        this.breakpointObserver
            .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
            .subscribe(() => this.updateDrawer());

        this.updateDrawer();
    }

    updateDrawer() {
        const isXSmall = this.breakpointObserver.isMatched(Breakpoints.XSmall);

        this.menuMode = this.isLargeScreen ? 'shrink' : 'overlap';
        this.menuRevealMode = isXSmall ? 'slide' : 'expand';
        this.minMenuSize = isXSmall ? 0 : 60;
        this.shaderEnabled = !this.isLargeScreen;
    }

    toggleMenu = (e) => {
        this.menuOpened = !this.menuOpened;
        e.event.stopPropagation();
    }

    get isLargeScreen() {
        const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
        const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

        return isLarge || isXLarge;
    }

    get sizeClasses() {
        return {
            'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
            'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
            'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
            'screen-large': this.isLargeScreen,
        };
    }

    get hideMenuAfterNavigation() {
        return this.menuMode === 'overlap';
    }

    get showMenuAfterClick() {
        return !this.menuOpened;
    }

    navigationChanged(event) {
        const path = event.itemData.path;
        const pointerEvent = event.event;

        if (path && this.menuOpened) {
            if (event.node.selected) {
                pointerEvent.preventDefault();
            } else {
                this.router.navigate([path]);
            }

            if (this.hideMenuAfterNavigation) {
                this.menuOpened = false;
                pointerEvent.stopPropagation();
            }
        } else {
            pointerEvent.preventDefault();
        }
    }

    translateMenuItems(navigation)
    {
        return this.translateForAllLevels(navigation);
    }

    translateForAllLevels(item){
        for(let i = 0; i < item.length; i++){
            if(item[i].items instanceof Array){
                this.translate.get(item[i].text).subscribe(res => { item[i].text = res});
                this.translateForAllLevels(item[i].items);
            }else{
                this.translate.get(item[i].text).subscribe(res => { item[i].text = res});
            }
        }
        
        return item
    }

    navigationClick() {
        if (this.showMenuAfterClick) {
            this.menuOpened = true;
        }
    }
}
@NgModule({
    imports: [ SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxToolbarModule, DxScrollViewModule, CommonModule ],
    exports: [ SideNavInnerToolbarComponent ],
    declarations: [ SideNavInnerToolbarComponent ]
})
export class SideNavInnerToolbarModule { }
