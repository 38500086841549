import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient } from "@angular/common/http";
import { AlertService} from '@/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DatasourcePivotTableService {

  constructor(private httpClient: HttpClient, private alert: AlertService, private translate: TranslateService, private datePipe: DatePipe) {}

  public getDatasource(dateInput: Date, reportKind: number, quater: number) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== "";
    }

  const apiUrl = environment.apiUrl +  'Reports/Orders?dateInput=' + this.datePipe.transform(dateInput,"yyyy-MM-dd") + "&reportKind=" + reportKind + "&quarter=" + quater;
  return new CustomStore({
    useDefaultSearch: true,
    key: "Oid",
    loadMode: "raw",
    load: () => {
        return this.httpClient.get(apiUrl)
            .toPromise();
    }
    });
  }
}