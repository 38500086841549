import { NgModule, Component, enableProdMode,  Inject, OnInit } from "@angular/core";
import { HttpClient, HttpClientModule, HttpParams } from "@angular/common/http";
import { DxDataGridModule } from "devextreme-angular";
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import { DatasourceDatagridService, DatasourceLookupService, AlertService, AuthenticationService} from "@/services/";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Order } from '@/models';

@Component({
  selector: 'orderdate-datagrid',
  templateUrl: './orderdate-datagrid.component.html',
  styleUrls: ['./orderdate-datagrid.component.scss']
})
export class OrderdateDatagridComponent implements OnInit {
  dataSource: DataSource;
  destroyed: Subject<void>;
  rowIndex: number;
  dataSourceOrganisation: any = {};


  constructor(
    private datasourceDatagridService: DatasourceDatagridService, 
    private alert: AlertService, 
    private authenticationService: AuthenticationService,
    private translateService: TranslateService) 
    { 
      this.destroyed = new Subject<void>(); 
      this.translateStatusValue = this.translateStatusValue.bind(this);
    }

    ngOnInit(){
      this.authenticationService.getOrganisationObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
          this.dataSource = this.datasourceDatagridService.getDatasource("OrderDate");          
      })
      this.dataSource = this.datasourceDatagridService.getDatasource("OrderDate");   
    }
  
    ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      this.destroyed.next();
      this.destroyed.complete();   
    }

    translateStatusValue(datarow: Order) {
      switch (datarow.OrderStatusId) {
        case 1: {
          return this.translateService.instant('orders.status.caption.initialized');
          break;
        }
        case 2: {
          return this.translateService.instant('orders.status.caption.paid');
          break;
        }
        case 3: {
          return this.translateService.instant('orders.status.caption.pack');
          break;
        }
        case 4: {
          return this.translateService.instant('orders.status.caption.send');
          break;
        }
        default: {
          return ""
          break;
        }
      }
    }
  }
