import { IPlatformAccount} from '@/interfaces'

export class PlatformAccount implements IPlatformAccount {

  constructor() {}
  
  Oid: number;
  PlatformName: string;
  PlatformOid: number;
  Organization: string;
  OrganizationOid: number;
  AuthenticationToken: string;
  Active: boolean;
  HasError: boolean;
  LastOrderPullDate: Date;
  CreateDate: Date;
  LastModifiedDate: Date;
  GuidCode: string;
  ErrorMessage: string;
  DefaultVatProductPercentage: number;
  DefaultVatShippingPercentage: number;
  PriceInclVat: boolean;
  UseDefaultVat: boolean;
  CanChangeTotal: boolean;
  HasFeeProducts: boolean;
  PrintPackingSlip: boolean;
  PrintInvoice: boolean;
  SendEmailPaid: boolean;
  SendEmailInvoice: boolean;
  SendEmailTrackAndTrace: boolean;
  AddFee: boolean;
}