import { NgModule, Component, enableProdMode,  Inject, OnInit } from "@angular/core";
import { HttpClient, HttpClientModule, HttpParams } from "@angular/common/http";
import { DxDataGridModule } from "devextreme-angular";
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import { DatasourceDatagridService, DatasourceLookupService, AlertService, AuthenticationService, DatasourceDatagridDetailService} from "@/services/";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {  _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Product } from '@/models';

@Component({
  selector: 'product-datagrid',
  templateUrl: './product-datagrid.component.html',
  styleUrls: ['./product-datagrid.component.scss']
})
export class ProductDatagridComponent implements OnInit {
  dataSource: DataSource;
  detailStores: any = {};
  platformAccountStores: any = {};
  feeStores: any = {};
  calculationStores: any = {};
  destroyed: Subject<void>;
  rowIndex: number;
  productKey: number;
  product: Product;
  vatClass = [];


  constructor(
    private datasourceDatagridService: DatasourceDatagridService,
    private datasourceDatagridDetailService: DatasourceDatagridDetailService,  
    private alert: AlertService, 
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private datasourceLookupService: DatasourceLookupService) 
    { 
      this.destroyed = new Subject<void>(); 
    }

    ngOnInit(){
      this.authenticationService.getOrganisationObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
          this.dataSource = this.datasourceDatagridService.getDatasource("Product");
          this.detailStores = this.datasourceDatagridDetailService.getDetailItems("ProductPlatform", 0);
          this.platformAccountStores = this.datasourceLookupService.getDatasource("PlatformAccount");
          this.feeStores = this.datasourceLookupService.getDatasource("Fee");
      })
      this.platformAccountStores = this.datasourceLookupService.getDatasource("PlatformAccount");
      this.feeStores = this.datasourceLookupService.getDatasource("Fee");
      this.dataSource = this.datasourceDatagridService.getDatasource("Product");
      this.setData();
    }

    getProducts(masterKey: any) {
      if (!this.detailStores.hasOwnProperty(masterKey))
      {
        this.detailStores[masterKey] = this.datasourceDatagridDetailService.getDetailItems("ProductPlatform", masterKey);
      }
      return this.detailStores[masterKey];
    }


    setData(){
      this.vatClass = [{
        "Oid": 0,
        "Name": _('BTW 0%')
      },{
        "Oid": 1,
        "Name": _('BTW hoog')
      },{
        "Oid": 2,
        "Name": _('BTW laag')
      }]
    }

    selectionChanged(e) {
      console.log(e);
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    }

    insertrow(data: any, key: any) {
      data.data["ProductId"] = key
    }

    rowInserting(data: any){
        var row = data.data;
        if (row["FeeId"] == null)
          row["FeeId"] = -1;
    }

    initrow(data: any, master: any) {    
       data.data.Name = master.data.Name;
       data.data.Active = true;
    }

    initrowProduct(data: any) {    
      data.data.CurrentStock = 0;
      data.data.CostPrice = 0.00;
      data.data.Active = true;
   }
  
    ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      this.destroyed.next();
      this.destroyed.complete();   
    }
  }
