import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {DatePipe} from '@angular/common';
import { AlertService} from '@/services/alert.service';
import { Observable } from "rxjs";
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Order } from '@/models';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(  private httpClient: HttpClient, 
                private alert: AlertService, 
                private datePipe: DatePipe) {}

  
    public downloadOrderList(startdate: Date, enddate: Date) {

        return this.httpClient.post(environment.apiUrl +  'Reports/OrderList?startdate=' + this.datePipe.transform(startdate,"yyyy-MM-dd") + "&enddate=" +  this.datePipe.transform(enddate,"yyyy-MM-dd") + "&pdf=true", {location: "Bestelijst.pdf"}, { responseType: 'blob' });
    }

    public downloadStockList(startdate: Date, enddate: Date) {

        return this.httpClient.post(environment.apiUrl +  'Reports/StockList?startdate=' + this.datePipe.transform(startdate,"yyyy-MM-dd") + "&enddate=" +  this.datePipe.transform(enddate,"yyyy-MM-dd"), {location: "Voorraadlijst.xlsx"}, { responseType: 'blob' });
    }

    public downloadAdministration(startdate: Date) {
        return this.httpClient.post(environment.apiUrl +  'Reports/Administration?dateInput=' + this.datePipe.transform(startdate,"yyyy-MM-dd"), {location: "Administration.xlsx"}, { responseType: 'blob' });
    }

    public downloadCommissieReport(startdate: Date) {
        return this.httpClient.post(environment.apiUrl +  'Reports/Commissie?dateInput=' + this.datePipe.transform(startdate,"yyyy-MM-dd"), {location: "Commissie.xlsx"}, { responseType: 'blob' });
    }

    public downloadFee(startdate: Date) {
        return this.httpClient.post(environment.apiUrl +  'Reports/Kaffeesteuer?dateInput=' + this.datePipe.transform(startdate,"yyyy-MM-dd"), {location: "Kaffeesteuer.xlsx"}, { responseType: 'blob' });
    }

    
        

}