import { IProduct} from '@/interfaces'

export class Product implements IProduct {

  constructor() {}

  Oid: number;
  Name: string;
  EAN: string;
  CurrentStock: number;
  CostPrice: number;
  Weight: number;
  Vat: number;
  OrganizationOid: number;
  FeeId: number;
  Location: string;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
}