import { NgModule, Component, enableProdMode,  Inject, OnInit } from "@angular/core";
import { HttpClient, HttpClientModule, HttpParams } from "@angular/common/http";
import { DxDataGridModule } from "devextreme-angular";
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import { DatasourceDatagridService, DatasourceLookupService, AlertService, AuthenticationService, DatasourceDatagridDetailService} from "@/services/";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Country, CountryName } from '@/models';



@Component({
  selector: 'country-datagrid',
  templateUrl: './country-datagrid.component.html'
  
})
export class CountryDatagridComponent {
  dataSource: DataSource;
  detailStores: any = {};
  languageStores: any = {};
  destroyed: Subject<void>;
  rowIndex: number;
  productKey: number;
  country: Country;
  countryName: CountryName;
  
  constructor(
    private datasourceDatagridService: DatasourceDatagridService,
    private datasourceDatagridDetailService: DatasourceDatagridDetailService,  
    private alert: AlertService, 
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private datasourceLookupService: DatasourceLookupService) 
    { 
      this.destroyed = new Subject<void>(); 
    }

  ngOnInit(){
    this.authenticationService.getOrganisationObservable()
    .pipe(takeUntil(this.destroyed))
    .subscribe(() => {
        this.dataSource = this.datasourceDatagridService.getDatasource("Country");
        this.detailStores = this.datasourceDatagridDetailService.getDetailItems("Country", 0);
        this.languageStores = this.datasourceLookupService.getDatasource("Language");
    })
    this.dataSource = this.datasourceDatagridService.getDatasource("Country");
  }

  getCountryNames(masterKey: any) {
    if (!this.detailStores.hasOwnProperty(masterKey))
    {
      this.detailStores[masterKey] = this.datasourceDatagridDetailService.getDetailItems("Country", masterKey);
    }
    return this.detailStores[masterKey];
  }

  insertrow(data: any, key: any) {
    data.data["CountryId"] = key
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed.next();
    this.destroyed.complete();   
  }
}