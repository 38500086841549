import { IPlatform} from '@/interfaces'

export class Platform implements IPlatform {

  constructor() {}
  
  Oid: number;
  Name: string;
  ChargeInclusive: boolean;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
  OauthAuthentication: boolean;
}