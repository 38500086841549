import { ICountryName} from '@/interfaces'

export class CountryName implements ICountryName {

  constructor() {}

  Oid: number;
  Name: string;
  LanguageId: number;
  CountryId: number;
  CreateDate: Date;
  LastModifiedDate: Date;
}