import { IOrder} from '@/interfaces'
import { OrderLine } from './orderLine';

export class Order implements IOrder {

    constructor() {}
    
    Oid: number;
    PlatformName: string;
    PlatformAccountId: number;
    CreatedDate: Date;
    LastModifiedDate: Date;
    TransactionId: string;
    ExternalOrderNumber: string
    OrderNumber: string;
    InvoiceNumber: number;
    InvoiceDate: Date;
    StatusName: string;
    OrderStatusId: number;
    ExternalStatus: string;
    SubTotalPriceInclVat: number;
    SubTotalPriceExclVat: number;
    SubTotalVat: number;
    TotalPriceInclVat: number;
    TotalPriceExclVat: number;
    TotalVat: number;
    TotalWeight: number;
    TotalFee: number;
    ShippingCostInclVat: number;
    ShippingCostExclVat: number;
    ShippingCostVatPercentage: number;
    ShippingCostVat: number;
    DiscountInclVat: number;
    DiscountExclVat: number;
    DiscountVat: number;
    TotalProducts: number;
    PlatformCreateDate: Date;
    SuccessfullyParseAddress: boolean;
    PublicNote: string;
    PrivateNote: string;
    Paid: boolean;
    HasError: boolean;
    ErrorMessage: string;
    LanguageId: number;
    CarrierSelectionsId: number;
    TrackAndTrace: string;
    AmountLabelsToPrint: number;
    PrintInvoiceDateTime: Date;
    PrintLabelDateTime: Date;
    PrintPackingSlipDateTime: Date;
    PrintInvoice: boolean;
    PrintLabel: boolean;
    PrintPackingSlip: boolean;
    ResetPrintPackageSlip: boolean;
    ResetPrintInvoice: boolean;
    ResetPrintLabel: boolean;
    FeeTotalExcl: number;
    FeeVat: number;
    FeeTotalPercentage: number;
    VatNumber: string;
    HasPrintLabel: boolean;
    IsOrganization: boolean;
    
    //Order
    OrderUserName: string;
    OrderFirstName: string;
    OrderLastName: string;
    OrderCountry: string;
    OrderRegion: string;
    OrderCity: string;
    OrderZipCode: string;
    OrderAddressSupplement: string;
    OrderAddressRaw: string;
    OrderAddress1: string;
    OrderAddress2: string;
    OrderHouseNumberExt: string;
    OrderHouseNumber: string;
    OrderStreet: string;
    OrderEmail: string;
    OrderPhone: string;
    OrderMobile: string;
    OrderFullName: string;
    OrderGender: string;
    OrderCompany: string;
    OrderCountryId: number;

    //Shipping
    ShippingCountry: string;
    ShippingRegion: string;
    ShippingCity: string;
    ShippingZipCode: string;
    ShippingAddressSupplement: string;
    ShippingAddressRaw: string;
    ShippingAddress1: string;
    ShippingAddress2: string;
    ShippingHouseNumberExt: string;
    ShippingHouseNumber: string;
    ShippingStreet: string;
    ShippingEmail: string;
    ShippingPhone: string;
    ShippingMobile: string;
    ShippingFullName: string;
    ShippingFirstName: string;
    ShippingLastName: string;
    ShippingGender: string;
    ShippingCompany: string;
    ShippingCountryId: number;

    //Billing
    BillingCountry: string;
    BillingRegion: string;
    BillingCity: string;
    BillingZipCode: string;
    BillingAddressSupplement: string;
    BillingAddressRaw: string;
    BillingAddress1: string;
    BillingAddress2: string;
    BillingHouseNumberExt: string;
    BillingHouseNumber: string;
    BillingStreet: string;
    BillingEmail: string;
    BillingPhone: string;
    BillingMobile: string;
    BillingFullName: string;
    BillingFirstName: string;
    BillingLastName: string;
    BillingGender: string;
    BillingCompany: string;
    BillingCountryId: number;

    //Orderlines
    OrderLines: OrderLine[];
}
