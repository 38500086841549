import { ICarrier} from '@/interfaces'

export class Carrier implements ICarrier {

  constructor() {}

  Oid: number;
  Name: string;
  TrackAndTraceUrl: string;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
  }