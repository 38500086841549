import { IUser} from '@/interfaces'

export class User implements IUser {

    constructor() {}
    
    SecurityStamp: string;
    DisplayName: string;
    Email: string;
    UserName: string;
    PassWord: string;
    FirstName: string;
    LastName: string;
    OrganizationOid: number;
    OrganizationName: string;
    Token: string;
    Expiration: number;
    ExpirationDate: Date;
    RoleOid: number;
    RoleName: string;
}