import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import DataSource from "devextreme/data/data_source";
import { HttpClient, HttpParams} from "@angular/common/http";
import { AlertService} from '@/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Observable } from 'rxjs';
import { Product } from '@/models';

@Injectable({
  providedIn: 'root'
})
export class DatasourceDatagridService {

  constructor(private httpClient: HttpClient, private alert: AlertService, private translate: TranslateService) {}

  public getOne(url: string, key:any) : Observable<Object>
  {
    const apiUrl = environment.apiUrl + url;
    return this.httpClient.get<Object>(apiUrl + '/' + encodeURIComponent(key))
  }

  public getDatasource(url: string) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== "";
    }
  const apiUrl = environment.apiUrl + url;
  return new DataSource({
    key: "Oid",
    load: (loadOptions) => {
      let params: HttpParams = new HttpParams();
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary"
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i]))
          params = params.set(i, JSON.stringify(loadOptions[i]));
      });
      return this.httpClient.get(apiUrl, { params: params })
        .toPromise()
        .then(result => {
          return result;
        })
        .catch(err => {
          console.log(err.error);
          var message = _('datasourceDatagridService.loadError');
          this.translate.get(message).subscribe(res => { this.alert.error(res) });
          return Promise.reject();
     });
    }, // ~load
    byKey: (key) => {
      return this.httpClient.get(apiUrl + '/' + encodeURIComponent(key))
        .toPromise();
    }, 
    update: (key, values) => {
      return this.httpClient.put(apiUrl + '/' + encodeURIComponent(key), values)
        .toPromise()
        .then(result => {
          var message = _('datasourceDatagridService.successfullyUpdated');
          this.translate.get(message).subscribe(res => { this.alert.success(res) });
          return result;
        })
        .catch(err => {
           console.log(err.error);
           var message = _('datasourceDatagridService.updateError');
           this.translate.get(message).subscribe(res => { this.alert.error(res) });
           return Promise.reject();
      });
    }, //~insert
    insert: (values) => {
      return this.httpClient.post(apiUrl + '/', values)
      .toPromise()
      .then(result => {
        var message = _('datasourceDatagridService.successfullyInsert');
        this.translate.get(message).subscribe(res => { this.alert.success(res) });
        return result;
      })
      .catch(err => {
         console.log(err.error);
         var message = _('datasourceDatagridService.insertError');
         this.translate.get(message).subscribe(res => { this.alert.error(res) });
         return Promise.reject();
    });
    }, //~insert
    remove: (key) => {
      return this.httpClient.delete(apiUrl + '/' + encodeURIComponent(key))
      .toPromise()
      .then(result => {
        var message = _('datasourceDatagridService.successfullyDeleted');
        this.translate.get(message).subscribe(res => { this.alert.success(res) });
      })
      .catch(err => {
         console.log(err.error);
         var message = _('datasourceDatagridService.deleteError');
         this.translate.get(message).subscribe(res => { this.alert.error(res) });
         return Promise.reject();
    });
    }, //~remove
    errorHandler: function(error) {
      var message = _('datasourceDatagridService.defaultError');
      this.translate.get(message).subscribe(res => { this.alert.error(res) });
      console.log(error.message);
    }
  });
  }
}