import { IOrganisation} from '@/interfaces'

export class Organisation implements IOrganisation {

  constructor() {}
  
  Oid: number;
  Name: string;
  SendGridEmailFrom: string;
  SendGridDisplayNameFrom: string;
  HasFeeProducts: boolean;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
}