import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { IAlertmessage } from '@/interfaces'


@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<IAlertmessage>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
//                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        let alert: IAlertmessage = {message: message, type:"success", showtime:2000 }
        this.subject.next(alert);
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        let alert: IAlertmessage = {message: message, type:"error", showtime:10000 }
        this.subject.next(alert);
    }

    info(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        let alert: IAlertmessage = {message: message, type:"info", showtime:5000 }
        this.subject.next(alert);
    }

    warning(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        let alert: IAlertmessage = {message: message, type:"warning", showtime:5000 }
        this.subject.next(alert);
    }

    getMessage(): Observable<IAlertmessage> {
        return this.subject.asObservable();
    }
}