import { Component, OnInit, OnDestroy} from '@angular/core';
import { HttpClient, HttpParams} from "@angular/common/http";
import { AlertService, StatusService, AuthenticationService } from '@/services'
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PlatformAccount } from '@/models'
import { environment } from '@environments/environment';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
  selector: 'app-platformaccount-status',
  templateUrl: './platformaccount-status.component.html',
  styleUrls: ['./platformaccount-status.component.scss']
})
export class PlatformaccountStatusComponent implements OnInit {

  destroyed: Subject<void>;
  platformAccountStatuses : PlatformAccount[] = [];

  constructor(private status: StatusService, private alert: AlertService,  private authenticationService: AuthenticationService) { 
    this.destroyed = new Subject<void>();
  }

  ngOnInit() {
    this.authenticationService.getOrganisationObservable()
    .pipe(takeUntil(this.destroyed))
    .subscribe(() => {
      this.getPlatformAccountStatus();
    })
    this.getPlatformAccountStatus();
  }

  getPlatformAccountStatus() {
    this.status.getPlatformAccountStatus().subscribe(
        data => { this.platformAccountStatuses = data},
        err => console.error(err),
        () => console.log('done loading PlatformAccountStatus')
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed.next();
    this.destroyed.complete();   
  }

}
