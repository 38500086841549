import { Component, NgModule, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { AuthenticationService } from '@/services';
import { User } from '@/models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-user-panel',
    templateUrl: 'user-panel.component.html',
    styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent implements OnDestroy{
    @Input()
    menuItems: any;

    @Input()
    menuMode: string;

    @Output()
    itemClick: EventEmitter<any> = new EventEmitter<any>();

    user: User = null;
    subscription: Subscription;

    constructor(private authenticationService: AuthenticationService) {
        this.subscription = this.authenticationService.currentUser.subscribe(user => {
            this.user = user;
        });
    }

    ngOnDestroy(): void {
        if(this.subscription != null) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    itemMenuClick(args) {
        this.itemClick.next(args.itemData);
    }
}

@NgModule({
    imports: [
        DxListModule,
        DxContextMenuModule,
        CommonModule
    ],
    declarations: [ UserPanelComponent ],
    exports: [ UserPanelComponent ]
})
export class UserPanelModule { }
