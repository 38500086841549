import { Injectable, Injector } from "@angular/core";
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthenticationInjector implements HttpInterceptor {
    
    constructor(private injector: Injector) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        const service = this.injector.get(AuthenticationService);
        const token = service.currentUserValue != null ? service.currentUserValue.Token : null;
        const organisationOid = service.getCurrentOrganisation();


        // Uncomment als ik verder ga met magento integratie. 
        //de interceptor moet niet activeren als er een magento2 call gedaan wordt, anders wel. 

        //var sessionCode = sessionStorage.getItem('AuthCode');
        //var sessionPlatformName = sessionStorage.getItem('PlatformName');

        //if (token && sessionCode == null && sessionPlatformName != "Magento 2") {
        if (token) {
            request = request.clone({
              setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        if (organisationOid) {
            request = request.clone({
                setHeaders: {
                    company: `${organisationOid}`
                }
            });
        }

        return next.handle(request);
    }
}