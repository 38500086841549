import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html'
})
export class FeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
