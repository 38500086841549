import { NgModule  } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule} from 'devextreme-angular/ui/load-indicator';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { CommonModule } from '@angular/common';  
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { CountryDatagridComponent, OrganizationDatagridComponent, platformDatagridComponent, EmailKindDatagridComponent, CarrierSelectionDatagridComponent, TransactionalEmailDatagridComponent,
    ExceptionWordsDatagridComponent, OrderdateDatagridComponent, 
    PlatformAccountDatagridComponent, OrderDatagridComponent, PlatformaccountStatusComponent, ProductDatagridComponent, LanguageDatagridComponent, CarrierDatagridComponent,
    FeeDatagridComponent, OverviewComponent } from '@/components/'
import { CountryComponent, PlatformComponent, PlatformAccountComponent, OrganizationsComponent, OrderComponent, EmailkindComponent, CarrierselectionComponent,
    TransactionalemailComponent, OrderDateComponent,
    ProductComponent, LanguageComponent, FeeComponent, CarrierComponent, ExceptionWordsComponent, ReportsComponent } from '@/pages'

const routes: Routes = [{
        path: 'home',
        component: HomeComponent
    }, {
        path: 'Products',
        component: ProductComponent
    },{
        path: 'Orders',
        component: OrderComponent
    },{
        path: 'Orders/:id',
        component: OrderComponent
    },{
        path: 'profile',
        component: ProfileComponent
    }, {
        path: 'Fees',
        component: FeeComponent
    }, {
        path: 'Languages',
        component: LanguageComponent
    }, {
        path: 'Countries',
        component: CountryComponent
    }, {
        path: 'Organisations',
        component: OrganizationsComponent
    }, {
        path: 'Carrier',
        component: CarrierComponent
    }, {
        path: 'Reports',
        component: ReportsComponent
    }, {
        path: 'Carrierselection',
        component: CarrierselectionComponent
    }, {
        path: 'Emailkind',
        component: EmailkindComponent
    }, {
        path: 'TransactionalEmail',
        component: TransactionalemailComponent
    }, {
        path: 'Platforms',
        component: PlatformComponent
    }, {
        path: 'OrderDates',
        component: OrderDateComponent
    }, {
            path: 'Platformsaccounts',
            component: PlatformAccountComponent     
    }, {
            path: 'ExceptionWords',
            component: ExceptionWordsComponent    
    }];

    
@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, DxTabPanelModule, DxPivotGridModule, DxFormModule, BrowserModule, DxLoadPanelModule, DxValidationSummaryModule, CommonModule, DxButtonModule, DxPopupModule, DxLookupModule,
    DxTextBoxModule, DxAccordionModule, DxSelectBoxModule, DxDateBoxModule, DxLoadIndicatorModule, DxCheckBoxModule, ClipboardModule, DxDropDownButtonModule, FormsModule, TranslateModule, DxTextAreaModule, DxNumberBoxModule, DxValidatorModule, DxScrollViewModule],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, CountryDatagridComponent, CountryComponent, TransactionalEmailDatagridComponent, ExceptionWordsComponent,ExceptionWordsComponent, ExceptionWordsDatagridComponent,
     OrganizationsComponent, OverviewComponent, ReportsComponent, CarrierDatagridComponent,EmailkindComponent, EmailKindDatagridComponent, CarrierselectionComponent, CarrierSelectionDatagridComponent, TransactionalemailComponent,
     OrganizationDatagridComponent, PlatformComponent, platformDatagridComponent, PlatformAccountComponent, LanguageDatagridComponent, LanguageComponent, FeeComponent, FeeDatagridComponent,
     PlatformAccountDatagridComponent, OrderDatagridComponent, OrderComponent, PlatformaccountStatusComponent, CarrierComponent, ProductDatagridComponent, ProductComponent, OrderDatagridComponent, OrderDateComponent, OrderdateDatagridComponent]
})
export class AppRoutingModule { }
