import { NgModule, Component, enableProdMode,  Inject, OnInit } from "@angular/core";
import { HttpClient, HttpClientModule, HttpParams } from "@angular/common/http";
import { DxDataGridModule } from "devextreme-angular";
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import { DatasourceDatagridService, DatasourceLookupService, AlertService, AuthenticationService} from "@/services/";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Fee } from '@/models';

@Component({
  selector: 'fee-datagrid',
  templateUrl: './fee-datagrid.component.html',
  styleUrls: ['./fee-datagrid.component.scss']
})
export class FeeDatagridComponent implements OnInit {
  dataSource: DataSource;
  detailStores: any = {};
  calculationStores: any = {};
  destroyed: Subject<void>;
  rowIndex: number;


  constructor(
    private datasourceDatagridService: DatasourceDatagridService,
    private alert: AlertService, 
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private datasourceLookupService: DatasourceLookupService) 
    { 
      this.destroyed = new Subject<void>(); 
    }

    ngOnInit(){
      this.authenticationService.getOrganisationObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
          this.dataSource = this.datasourceDatagridService.getDatasource("Fee");
          this.calculationStores = this.datasourceLookupService.getDatasource("Calculation");
      })
      this.dataSource = this.datasourceDatagridService.getDatasource("Fee");
      this.calculationStores = this.datasourceLookupService.getDatasource("Calculation");
    }
  
    ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      this.destroyed.next();
      this.destroyed.complete();   
    }
  }
