import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrierselection',
  templateUrl: './carrierselection.component.html'
})
export class CarrierselectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
