import { Component, NgModule, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { LoginFormModule } from '../login-form/login-form.component';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { User, Organisation } from '@/models';
import { IOrganisation } from '@/interfaces';
import { UserService, AuthenticationService } from '@/services';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
    currentUser: User;
    currentUserSubscription: Subscription;
    currentOrganisationSubscription: Subscription;
    users: User[] = [];
    organisations: Organisation[];
    ownOrganisation = 1;

    onOrganisationChangedCallback = null;

    @Output()
    menuToggle = new EventEmitter<boolean>();
    
    @Input()
    menuToggleEnabled = false;

    @Input()
    title: string;

    showLoginPopup = false;
    isUserAuthorized =  false;
    userMenuItems = [{
        text: 'Profile',
        itemIcon: 'user'
    }, {
        text: 'Logout',
        itemIcon: 'runner'
    }];

    constructor(private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
    ) {

        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
            this.isUserAuthorized = user != null;            
            this.showLoginPopup = user == null;


            if(user == null) {
                this.organisations = [];
                this.router.navigateByUrl("home");
            } else {
                this.currentOrganisationSubscription = this.authenticationService.GetOrganisations().subscribe(res => {
                    this.organisations = <Organisation[]> res;
                    this.SetOrganisationList();
                });
            }
        });
    }

    private SetOrganisationList()
    {
        if (this.currentUser != null)
        {
                this.ownOrganisation = this.currentUser.OrganizationOid;
        }  else {
            this.organisations = [];
        }
    }

    onOrganistionChanged = (e) => {
        let organisation = <Organisation> e.selectedItem;
        this.authenticationService.SetOrganisationHeaderOid(organisation.Oid.toString());
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.currentUserSubscription.unsubscribe();
        this.currentOrganisationSubscription.unsubscribe();
    }

    ngOnInit() {
        // this.loadAllUsers();
     }

    toggleMenu = () => {
        this.menuToggle.emit();
    }

    onShowLoginPopup = () => {
        this.showLoginPopup = true;
    }

    onUserMenuItemClick(item) {
        if (item === this.userMenuItems[1]) {
            this.authenticationService.logout();
        }
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        UserPanelModule,
        DxToolbarModule,
        LoginFormModule
    ],
    declarations: [ HeaderComponent ],
    exports: [ HeaderComponent ]
})
export class HeaderModule { }
