import { NgModule, Component, enableProdMode, Inject } from "@angular/core";
import { HttpClient, HttpClientModule, HttpParams } from "@angular/common/http";
import { DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { environment } from "@environments/environment"
import { DatasourceDatagridService} from "@/services/";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'organization-datagrid',
  templateUrl: './organization-datagrid.component.html',
  styleUrls: ['./organization-datagrid.component.scss']
})
export class OrganizationDatagridComponent {

  dataSource: any = {};

  constructor(
    private datasourceDatagridService: DatasourceDatagridService,
    private translate: TranslateService) {
    
  }

  ngOnInit(){
    this.dataSource = this.datasourceDatagridService.getDatasource("Organization");
  }
}