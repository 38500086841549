import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exceptionwords',
  templateUrl: './exceptionwords.component.html'
})
export class ExceptionWordsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
