import { Component, OnInit, NgModule, Output, EventEmitter, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from "@angular/router";
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { AuthenticationService, AlertService } from '@/services/';
import { environment } from '@environments/environment';
import { IAlertmessage} from '@/interfaces';



@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html'
})
export class LoginFormComponent implements OnInit {
    login = '';
    password = '';
    url = environment.apiUrl + 'token/auth';

    @Output()
    public userAuthorized: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router, private alert: AlertService, private authService: AuthenticationService) 
    {

    }

    ngOnInit() { }

    onLoginClick(args) {
        if (!args.validationGroup.validate().isValid) {
            return;
        }

        var url = url;
        var username = this.login;
        var password = this.password;


        this.authService.login(username, password)
            .subscribe(res => {
                this.userAuthorized.next(res);

                // login successful
                if(res == true){
                    args.validationGroup.reset();
                }
            },
            err => {
                this.userAuthorized.next(false);
                // login failed
                console.log(err);
                if(err.status == 401)
                {
                    this.alert.error("Incorrect username or password");
                }else{
                    this.alert.error(err.message);
                }
            });
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxButtonModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule
    ],
    declarations: [ LoginFormComponent ],
    exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
