import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html'
})
export class CarrierComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
