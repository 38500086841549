import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertService} from '@/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import { IPlatformAccount, IProduct, IFee, IOrder} from '@/interfaces'
import { saveAs } from 'file-saver';
import { Observable } from "rxjs";
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Order } from '@/models';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient, private alert: AlertService, private translateService: TranslateService) {}

    public InsertOrder(order: Order)
        {
            return this.httpClient.post<IOrder>(environment.apiUrl + "Order" + '/', order);
        }

        public UpdateOrder(order: Order)
        {
            return this.httpClient.put<IOrder>(environment.apiUrl + "Order/Update" + '/', order);
        }

        public CopyOrder(order: Order)
        {
            return this.httpClient.post<IOrder>(environment.apiUrl + "Order/Copy" + '/', order);   
        }

        public DeleteOrder(order: Order)
        {
            return this.httpClient.delete<IOrder>(environment.apiUrl + "Order" + '/' + order.Oid);           
        }

        public GetPlatformAcount(order: Order)
        {
            return this.httpClient.get<IPlatformAccount>(environment.apiUrl +  'PlatformAccount/' + order.PlatformAccountId);
        }

        GetProduct(productOid: number)
        {
           return this.httpClient.get<IProduct>(environment.apiUrl +  'Product/' + productOid);           
        }

        public GetFee(feeOid: number)
        {
            return this.httpClient.get<IFee>(environment.apiUrl +  'Fee/' + feeOid);
        }

        public downloadInvoice(orderId: number) {
            return this.httpClient.post(environment.apiUrl +  'Invoice/' + orderId, {location: "report.pdf"}, { responseType: 'blob' });
        }

        public getOrder(OrderOid: number)
        {
            return this.httpClient.get<Order>(environment.apiUrl +  'Order/' + OrderOid);
        }

        public Credit(order: Order)
        {
            return this.httpClient.post<Order>(environment.apiUrl + "Order/Credit" + '/', order);   
        }

        public SendInvoice(OrderOid: number)
        {
            return this.httpClient.get<Order>(environment.apiUrl + "Order/SendInvoice" + '/' + OrderOid);   
        }

        public CreateInvoice(OrderOid: number)
        {
            return this.httpClient.get<Order>(environment.apiUrl + "Order/CreateInvoice" + '/' + OrderOid);   
        }

}