import { ITransactionalEmail} from '@/interfaces'

export class TransactionalEmail implements ITransactionalEmail {

  constructor() {}

  Oid: number;
  Name: string;
  TransactionalEmailId: string;
  Body: string;
  EmailKindId: number;
  OrganizationId: number;
  LanguageId: number;
  Active: boolean;
  CreateDate: Date;
  LastModifiedDate: Date;
  }