import {  NgModule,  Component,  enableProdMode,  Inject,  Injectable,  OnDestroy, ChangeDetectorRef, ViewChild,  ChangeDetectionStrategy} from "@angular/core";
import {  ActivatedRoute } from '@angular/router';
import {  trigger,  transition,  animate,  style} from '@angular/animations';
import {  HttpClient,  HttpClientModule,  HttpParams} from "@angular/common/http";
import {  DxDataGridModule,  DxTextBoxModule,  DxSelectBoxModule, DxLoadPanelModule,  DxAccordionModule,  DxDataGridComponent,  DxPopupComponent,  DxLookupModule,  DxButtonModule,  DxNumberBoxModule,  DxLoadIndicatorModule,  DxButtonComponent,  DxScrollViewModule,  DxValidatorModule,  DxDropDownButtonModule,  DxValidationSummaryModule} from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {  BrowserAnimationsModule} from '@angular/platform-browser/animations';
import CustomStore from "devextreme/data/custom_store";
import {  environment} from "@environments/environment";
import {  DatasourceDatagridService,  DatasourceDatagridDetailService,  DatasourceLookupService,  AlertService,  AuthenticationService,  OrderService} from "@/services/";
import {  Subject} from "rxjs";
import {  takeUntil} from "rxjs/operators";
import {  confirm } from 'devextreme/ui/dialog';
import {  Order, PlatformAccount, Product, Fee} from '@/models';
import {  IOrder} from '@/interfaces';
import {  strict} from 'assert';
import {  stringify} from 'querystring';
import {  TranslateService} from '@ngx-translate/core';
import {  _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import {  TestBed} from '@angular/core/testing';
import { IProduct } from '@/interfaces';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { saveAs } from 'file-saver';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'order-datagrid',
  templateUrl: './order-datagrid.component.html',
  styleUrls: ['./order-datagrid.component.scss'],
})
export class OrderDatagridComponent {

  @ViewChild("dataGrid", {static: false}) dataGrid: DxDataGridComponent;
  @ViewChild(DxPopupComponent, {static: true}) popup: DxPopupComponent;
  @ViewChild("orderlineDataGrid", {static: false}) dgOrderlines: DxDataGridComponent;
  dataSource: DataSource;
  countryStores: any = {};
  productStores: any = {};
  carrierSelectionStores: any = {};
  languageStores: any = {};
  detailStores: any = {};
  destroyed: Subject <void> ;
  popupVisible = false;
  order: Order;
  platformAccount: PlatformAccount;
  rowIndex: number;
  changeDetection: ChangeDetectionStrategy.OnPush;
  showDetailOrder: boolean = true;
  isNewOrder: boolean = false;
  isOrderSaved: boolean = true;
  isLoading: boolean = true;
  toggleEmail: boolean = false;
  maxStep: any;
  minStep: any;
  btnInitialized: boolean = true;
  btnPaid: boolean = true;
  btnPack: boolean = true;
  btnSend: boolean = true;
  DisableCalc: boolean = true;
  loadIndicatorVisible: boolean = false;

  expanded = true;
  totalCount: number;

  btnNext: any;
  btnPrevious: any;
  statusArray: any;

  orderSettings: SimpleObject[];


  constructor(
    private datasourceDatagridService: DatasourceDatagridService,
    private datasourceDatagridDetailService: DatasourceDatagridDetailService,
    private datasourceLookupService: DatasourceLookupService,
    private orderService: OrderService,
    private alert: AlertService,
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private clipboardService: ClipboardService) 
    {
    this.destroyed = new Subject < void > ();
    this.translateStatusValue = this.translateStatusValue.bind(this);
    this.platformAccount = new PlatformAccount();
    this.translateService.instant('orders.caption.inclPrice');
    this.translateService.instant('orders.caption.exclPrice');
    this.translateService.instant('orders.caption.feeAdd');
    this.translateService.instant('orders.caption.feeSubtract');
  }

  onToolbarPreparing(e: any) {
    var toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item) => {
      if (item.name === "addRowButton") {
        item.options.onClick = () => {
          this.newOrder();
        };
        item.options.hint = this.translateService.instant('orders.status.hint.addneworder');
      }
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: "<i class='fas fa-business-time' style='padding-right:2px'></i>&nbsp; " + this.translateService.instant('orders.status.caption.label') + "&nbsp; "
    }, {
      location: 'before',
      widget: 'dxSelectBox',
      options: {
        width: 180,
        items: [{
          value: '-1',
          text: this.translateService.instant('orders.status.caption.all'),
          icon: 'far fa-hand-point-right'
        }, {
          value: '1',
          elementAttr: "{ id: '1' }",
          text: this.translateService.instant('orders.status.caption.initialized'),
          icon: 'fal fa-clipboard-check'
        }, {
          value: '2',
          text: this.translateService.instant('orders.status.caption.paid'),
          icon: 'fal fa-euro-sign'
        }, {
          value: '3',
          text: this.translateService.instant('orders.status.caption.pack'),
          icon: 'far fa-dolly-flatbed-alt'
        }, {
          value: '4',
          text: this.translateService.instant('orders.status.caption.send'),
          icon: 'far fa-truck'
        }],
        displayExpr: 'text',
        valueExpr: 'value',
        value: '-1',
        hint: this.translateService.instant('orders.status.hint.selectstatus'),
        onInitialized: (args: any) => {
          this.statusArray = args.component.option("items");
        },
        onValueChanged: this.groupChanged.bind(this)
      }
    }, {
      location: 'before',
      template: "<div style='width:25px'></div>"
    }, {
      location: 'before',
      widget: 'dxButton',
      options: {
        visible: false,
        icon: 'refresh',
        text: 'Previous',
        elementAttr: {
          class: "class-buttonshadow"
        },
        hint: this.translateService.instant('orders.status.hint.previousstep'),
        width: "180px",
        onInitialized: (args: any) => {
          this.btnPrevious = args.component
        },
        onClick: this.previousStepButtonClick.bind(this)
      }
    }, {
      location: 'before',
      template: "<div style='width:5px'></div>"
    }, {
      location: 'before',
      widget: 'dxButton',
      options: {
        visible: false,
        icon: 'refresh',
        text: 'Next',
        width: "180px",
        elementAttr: {
          class: "class-buttonshadow"
        },
        hint: this.translateService.instant('orders.status.hint.nextstep'),
        onInitialized: (args: any) => {
          this.btnNext = args.component
        },
        onClick: this.nextStepButtonClick.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        visible: true,
        icon: 'fal fa-mail-bulk',
        hint: this.translateService.instant('orders.status.hint.toggleEmail'),
        onClick: () => {
          this.toggleEmail = !this.toggleEmail;
        }
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        hint: this.translateService.instant('orders.status.hint.refresh'),
        onClick: this.refreshDataGrid.bind(this)
      }
    });
  }

  groupChanged(e) {
    this.changeButtons(parseInt(e.value, 0));
    if (e.value == '-1') {
      this.dataGrid.instance.clearSelection();
      this.dataGrid.instance.clearFilter();
    } else {
      this.dataGrid.instance.clearSelection();
      this.dataGrid.instance.filter(["OrderStatusId", "=", e.value]);
    }
  }

  changeButtons(e: number) {
    this.maxStep = this.statusArray.find(x => x.value == e + 1);
    this.minStep = this.statusArray.find(x => x.value == e - 1);
    if (this.minStep != undefined || this.minStep != null) {
      this.btnPrevious.option({
        text: this.minStep.text,
        visible: true,
        type: 'danger',
        icon: this.minStep.icon
      });
    } else {
      this.btnPrevious.option({
        visible: false,
      });
    }
    if (this.maxStep != undefined || this.maxStep != null) {
      this.btnNext.option({
        text: this.maxStep.text,
        visible: true,
        type: 'success',
        icon: this.maxStep.icon
      });
    } else {
      this.btnNext.option({
        visible: false,
      });
    }

  }

  translateStatusValue(datarow: Order) {
    switch (datarow.OrderStatusId) {
      case 1: {
        return this.translateService.instant('orders.status.caption.initialized');
        break;
      }
      case 2: {
        return this.translateService.instant('orders.status.caption.paid');
        break;
      }
      case 3: {
        return this.translateService.instant('orders.status.caption.pack');
        break;
      }
      case 4: {
        return this.translateService.instant('orders.status.caption.send');
        break;
      }
      default: {
        return ""
        break;
      }
    }
  }

  toggleOrderDetails() {
    this.showDetailOrder = !this.showDetailOrder;
  }

  newOrder() {
    this.isNewOrder = true;
    let o = new Order();
    this.order = o;
    this.order.PlatformAccountId = 7;
    this.order.PlatformName = "Manueel";
    this.order.StatusName = "Init" 
    this.order.OrderStatusId = 1; 
    this.order.PlatformCreateDate = new Date();
    this.order.SubTotalPriceExclVat = 0;
    this.order.SubTotalPriceInclVat = 0;
    this.order.SubTotalVat = 0;
    this.order.SuccessfullyParseAddress = true;
    this.order.TotalFee = 0;
    this.order.TotalPriceExclVat = 0;
    this.order.TotalPriceInclVat = 0;
    this.order.TotalProducts = 0;
    this.order.TotalVat = 0;
    this.order.TotalWeight = 0;
    this.order.TransactionId = "-";
    this.order.Oid = -1;
    this.order.OrderLines = null;
    this.order.DiscountExclVat = 0;
    this.order.DiscountInclVat = 0;
    this.order.DiscountVat = 0;
    this.order.HasError = false;
    this.order.ShippingCostExclVat = 0;
    this.order.ShippingCostInclVat = 0;
    this.order.ShippingCostVat = 0;
    this.order.ShippingCostVatPercentage = 0;
    this.order.HasPrintLabel = false;
    this.order.VatNumber = "";
    this.order.FeeTotalExcl = 0; 
    this.order.FeeVat = 0;
    this.order.FeeTotalPercentage = 0;
    this.order.IsOrganization = false;


    this.isOrderSaved = false;
    this.showOrder();  
  }

  refreshDataGrid() {
    this.dataGrid.instance.refresh();
  }

  selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  loadThisOrder(e)
  {
    this.order = e;
    this.DisableCalc = true;
    this.rowIndex = this.dataGrid.instance.getRowIndexByKey(this.order.Oid);
    this.showOrder();
  }

  showOrder() {
    this.popupVisible=true;
    this.disableStatusButtons();
    this.getPlatformAccount();
    this.DisableCalc = true;
    this.isOrderSaved = true;
  }

  getPlatformAccount()
  {
    this.orderService.GetPlatformAcount(this.order).subscribe(
      data => {
         this.platformAccount = data;
       //  this.changeDatagirdFee(this.platformAccount.HasFeeProducts);
        },
      err => console.error(err)
    );
  }

  platformNameFilter = [{
    text: 'Magento',
    value: ['PlatformName', '=', 'Magento'],
  }, {
    text: 'Amazon API',
    value: ['PlatformName', '=', 'Amazon API'],
  }, {
    text: 'Ebay',
    value: ['PlatformName', '=', 'Ebay'],
  }, {
    text: 'Manueel',
    value: ['PlatformName', '=', 'Manueel'],
  }, {
    text: 'Amazon old',
    value: ['PlatformName', '=', 'Amazon'],
  }];

  statusNameFilter = [{
    text: 'Geïnitialiseerd',
    value: ['PlatformName', '=', 'Geïnitialiseerd'],
  }, {
    text: 'Betaald',
    value: ['PlatformName', '=', 'Betaald'],
  }, {
    text: 'Ingepakt',
    value: ['PlatformName', '=', 'Ingepakt'],
  }, {
    text: 'Verzonden',
    value: ['PlatformName', '=', 'Verzonden'],
  }];

  changeDatagirdFee(HasFeeProducts: boolean)
  {  
    if (HasFeeProducts) {
      this.dgOrderlines.instance.columnOption( 'FeeValue', 'visible', true); 
      this.dgOrderlines.instance.columnOption( 'FeeValue', 'width', 110); 
      this.dgOrderlines.instance.columnOption( 'FeeName', 'visible', true); 
      this.dgOrderlines.instance.columnOption( 'FeeName', 'width', 30); 
      
    }else{
      this.dgOrderlines.instance.columnOption( 'FeeValue', 'visible', false);
      this.dgOrderlines.instance.columnOption( 'FeeValue', 'width', 0); 
      this.dgOrderlines.instance.columnOption( 'FeeName', 'visible', false); 
      this.dgOrderlines.instance.columnOption( 'FeeName', 'width', 0); 
    } 
  }

  disableStatusButtons() {
    switch(this.order.OrderStatusId) {      
      case 1: {
        this.btnInitialized = false;
        this.btnPaid = true;
        this.btnPack = false;
        this.btnSend = false;
        break;
      }

      case 2: {
        this.btnInitialized = true;
        this.btnPaid = false;
        this.btnPack = true;
        this.btnSend = false;
        break;
      }

      case 3: {
        this.btnInitialized = false;
        this.btnPaid = true;
        this.btnPack = false;
        this.btnSend = true;
        break;
      }

      case 4: {
        this.btnInitialized = false;
        this.btnPaid = false;
        this.btnPack = true;
        this.btnSend = false;
        break;
      }

      default: {
        this.btnInitialized = false;
        this.btnPaid = false;
        this.btnPack = false;
        this.btnSend = false;
        break;
      }
    }
  }

  onContentReadyDetailGrid($event) {
    if (this.isNewOrder == true)  this.dgOrderlines.instance.option("editing.allowAdding", false);  
    var ds: any = {};
    ds = this.dgOrderlines.instance.getDataSource();
    ds.store().load().done(rows => {
      this.calcSubTotal(rows);
    });
    ds.store
  }

  calcSubTotal(rows: any) {
    if (!this.DisableCalc)
    {
      let subTotalVATIncl: number = 0;
      let subTotalVATExcl: number = 0;
      let subTotalVAT: number = 0;
      let totalFee: number = 0;
      let totalWeight: number = 0;
      rows.forEach(function (row) {
        subTotalVATIncl += row['TotalPriceIncl'];
        subTotalVATExcl += row['TotalPriceExcl'];
        totalFee += row['FeeValue'];
        totalWeight += row['TotalWeight'];
      });    
      this.order.SubTotalPriceExclVat = parseFloat(subTotalVATExcl.toFixed(2));
      this.order.SubTotalPriceInclVat = parseFloat(subTotalVATIncl.toFixed(2));
      this.order.SubTotalVat =  parseFloat((this.order.SubTotalPriceInclVat - this.order.SubTotalPriceExclVat).toFixed(2));
      this.order.TotalWeight = parseFloat(totalWeight.toFixed(2));
      this.order.TotalFee = parseFloat(totalFee.toFixed(2));
      this.order.FeeTotalExcl =  parseFloat((this.convertToInclVat(this.order.TotalFee, this.order.FeeTotalPercentage)).toFixed(2)); //Inclusief aanpassen in db zodat het goed komt toekomst 
      this.order.FeeVat =  parseFloat((this.order.FeeTotalExcl - this.order.TotalFee).toFixed(2));
      if (this.order.FeeVat <= 0){
        this.order.FeeTotalPercentage = 0;
      }else{
        this.order.FeeTotalPercentage = this.order.FeeTotalPercentage == 0 ? this.platformAccount.DefaultVatProductPercentage : this.order.FeeTotalPercentage
      }
      
    }
  }

  nextStepButtonClick(e: any) {
    this.updateStatus(this.maxStep.value, this.dataGrid.instance.getSelectedRowKeys());
  }

  previousStepButtonClick(e: any) {
    let josn = JSON.stringify(this.dataGrid.instance.getSelectedRowKeys());
    this.updateStatus(this.minStep.value, this.dataGrid.instance.getSelectedRowKeys());
  }

  updateStatus(satuscode: number, rows: any[]) {
    this.loadIndicatorVisible = true;
    this.httpClient.put<boolean>(environment.apiUrl + "Order/UpdateOrderStatus" + '/' + encodeURIComponent(satuscode.toString()), rows)
      .toPromise()
      .then(result => {
        var message = _('datasourceDatagridService.successfullyInsert');
        this.translateService.get(message).subscribe(res => {
          this.alert.success(this.translateService.instant('orders.updateorderstatus.succes.message'));
          this.loadIndicatorVisible = false;        
        });
        if (result == true && rows.length < 2)
        {
          this.orderService.getOrder(this.order.Oid)
          .toPromise()
          .then(result => {              
              this.order = result;
              this.disableStatusButtons();
              this.dataGrid.instance.refresh();
              this.loadIndicatorVisible = false;
          });          
        }  else {
          this.dataGrid.instance.refresh();
        }      
      });
  }

  popup_hiding(e: any) {
    this.refreshDataGrid();
  }

  RowClick(e) {
    if(e.rowType === "data") {
      this.isLoading = true;
      this.loadIndicatorVisible = false;
      e.cancel = true;
      this.isNewOrder = false;
      this.loadThisOrder(e.data);
    }
}

  closeClick() {
    if (!this.isOrderSaved)
    {
      var result = confirm(this.translateService.instant('orders.save.hint.body'), this.translateService.instant('orders.save.hint.title'));  
      result.then((dialogResult) => {
        if (dialogResult) {
          this.saveOrder();
          this.popupVisible = false;
        }else{
          this.popupVisible = false;
          this.isOrderSaved = true;
        } 
      }); 
    }else{
      this.popupVisible = false;
    }
  }

  doneClick() {
    this.disableStatusButtons();
  }

  orderStatusButtons(e: number)
  {
    this.updateStatus(e, [this.order.Oid]);
    this.order.OrderStatusId = e;
    this.disableStatusButtons();    
  }

  saveClick() {
   this.saveOrder();
  }

  saveOrder()
  {
    if (this.isNewOrder) {
      this.orderService.InsertOrder(this.order)
      .toPromise()
      .then(result => {
          var message = _('datasourceDatagridService.successfullyInsert');
          this.translateService.get(message).subscribe(res => {
            this.alert.success(this.translateService.instant('orders.insertorder.succes.message'));
          });
          this.order = result;
          this.dgOrderlines.instance.option("editing.allowAdding", true);
      });

    } else {
      this.orderService.UpdateOrder(this.order)
      .toPromise()
      .then(result => {
          var message = _('datasourceDatagridService.successfullyInsert');
          this.translateService.get(message).subscribe(res => {
            this.alert.success(this.translateService.instant('orders.insertorder.succes.message'));
          });
          this.order = result;
      });
    }
    this.isOrderSaved = true;
    this.isNewOrder = false;
  }

  dataChanged()
  {
    if(!this.isLoading)
      this.isOrderSaved = false;
  }

  PopupContentReady(e: any){
    this.isOrderSaved = true;
    this.isLoading = false;
    this.loadIndicatorVisible = false;
  }

  getOrderItems(masterKey: any) {
    if (!this.detailStores.hasOwnProperty(masterKey)) { 
      this.detailStores[masterKey] = this.datasourceDatagridDetailService.getDetailItems("Order", masterKey);
    }
    return this.detailStores[masterKey];
    this.changeDatagirdFee(this.platformAccount.HasFeeProducts);
  }

  //Change events
  onRowInsertingDetailGrid(evt: any): void {
    evt.data.OrderOid = this.order.Oid;
  }

  onEditorPreparingDetailGrid(evt: any): void {
    this.loadIndicatorVisible = true;
    if (evt.parentType === 'dataRow' && (evt.dataField === 'ProductOid')) {
       evt.editorOptions.onOpened = function (e) { 
        e.component._popup.option('width', "600px"); 
        e.component._popup.option('minWidth', "600px"); 
        e.component._popup.option('maxWidth', "600px"); 
      };
    }  

    if (evt.dataField === "Amount") {
      evt.editorOptions.onValueChanged = (e: any) => {
        var itemPrice: number;
        var totalPriceIncl: number;
        var totalPriceExcl: number;
        var amount: number;
        var totalVat: number;
        var vatPercentage: number;
        var rowIndex: number;
        var product: Product;
        var fee: Fee;
        var productOid: number;

        this.DisableCalc = false;
        itemPrice = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "ItemPrice");
        totalPriceIncl = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalPriceIncl");
        totalPriceExcl = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalPriceExcl");
        vatPercentage = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "VatPercentage");
        totalVat = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalVat");
        productOid = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "ProductOid");
        amount = e.value;
        rowIndex = evt.row.rowIndex;

        totalPriceIncl = amount * itemPrice;

        totalPriceExcl = parseFloat(this.convertToExclVat(totalPriceIncl, vatPercentage).toFixed(2));
        totalVat = parseFloat(this.calcVat(totalPriceIncl, totalPriceExcl).toFixed(2));

        if (productOid != 0 || productOid != null) {
          this.orderService.GetProduct(productOid).subscribe((res) => {
            product = res; 
  
            console.log(this.platformAccount);
            this.updateRowWeightAndRepaint(rowIndex, product, amount);
  
            if (this.platformAccount.HasFeeProducts)
            {

              this.orderService.GetFee(product.FeeId).subscribe((f) => {
                fee = f;
                this.updateRowFeeAndRepaint(rowIndex, this.order, product,fee,amount);

                //Update value for save
                evt.setValue(amount);
                this.updateRowValuesAndRepaint(evt.row.rowIndex, amount, vatPercentage, itemPrice, totalPriceIncl, totalPriceExcl, totalVat);
                })
            }
          });
        }

        //Update value for save
        evt.setValue(amount);

        this.updateRowValuesAndRepaint(evt.row.rowIndex, amount, vatPercentage, itemPrice, totalPriceIncl, totalPriceExcl, totalVat);
      }
    }

    if (evt.dataField === "ItemPrice") {
      evt.editorOptions.onValueChanged = (e: any) => {
        var itemPrice: number;
        var totalPriceIncl: number;
        var totalPriceExcl: number;
        var amount: number;
        var totalVat: number;
        var vatPercentage: number;
        var rowIndex: number;

        this.DisableCalc = false;
        amount = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "Amount");
        totalPriceIncl = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalPriceIncl");
        totalPriceExcl = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalPriceExcl");
        vatPercentage = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "VatPercentage");
        totalVat = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalVat");
        itemPrice = e.value;
        rowIndex = evt.row.rowIndex;

        totalPriceIncl = amount * itemPrice;

        totalPriceExcl = parseFloat(this.convertToExclVat(totalPriceIncl, vatPercentage).toFixed(2));
        totalVat = parseFloat(this.calcVat(totalPriceIncl, totalPriceExcl).toFixed(2));

        //Update value for save
        evt.setValue(itemPrice);

        this.updateRowValuesAndRepaint(evt.row.rowIndex, amount, vatPercentage, itemPrice, totalPriceIncl, totalPriceExcl, totalVat);
      }
    }


    if (evt.dataField === "VatPercentage") {
      evt.editorOptions.onValueChanged = (e: any) => {
        var itemPrice: number;
        var totalPriceIncl: number;
        var totalPriceExcl: number;
        var amount: number;
        var totalVat: number;
        var newVatPercentage: number;
        var rowIndex: number;

        this.DisableCalc = false;
        itemPrice = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "ItemPrice");
        totalPriceIncl = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalPriceIncl");
        totalPriceExcl = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalPriceExcl");
        amount = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "Amount");
        totalVat = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "TotalVat");
        newVatPercentage = e.value;
        rowIndex = evt.row.rowIndex;

        totalPriceIncl = amount * itemPrice;

        totalPriceExcl = parseFloat(this.convertToExclVat(totalPriceIncl, newVatPercentage).toFixed(2));
        totalVat = parseFloat(this.calcVat(totalPriceIncl, totalPriceExcl).toFixed(2));

        //Update value for save
        evt.setValue(newVatPercentage);

        this.updateRowValuesAndRepaint(evt.row.rowIndex, amount, newVatPercentage, itemPrice, totalPriceIncl, totalPriceExcl, totalVat);
      }
    }

    if (evt.dataField === "ProductOid") {
      evt.editorOptions.onValueChanged = (e: any) => {        
        var amount: number;
        var productOid: number;
        var product : Product;
        var feeOid: number;
        var fee: Fee;
        var rowIndex: number;

        this.DisableCalc = false;
        rowIndex = evt.row.rowIndex;
        amount = this.dgOrderlines.instance.cellValue(evt.row.rowIndex, "Amount");
        productOid = e.value;

        this.orderService.GetProduct(productOid).subscribe((res) => {
          product = res; 
          
          evt.setValue(product.Oid);

          this.updateRowWeightAndRepaint(rowIndex, product, amount);
          console.log(this.platformAccount);
          if (this.platformAccount.HasFeeProducts)
          {
            this.orderService.GetFee(product.FeeId).subscribe((f) => {
              fee = f;
              this.updateRowFeeAndRepaint(rowIndex, this.order, product,fee,amount);
              })
          }
        });
      }
    }
    this.loadIndicatorVisible = false;
  }

  updateRowValuesAndRepaint(rowIndex: any, amount: number, newVatPercentage: number, itemPrice: number, totalPriceIncl: number, totalPriceExcl: number, totalVat: number) {
    //Update values inside row
    this.dgOrderlines.instance.cellValue(rowIndex, "VatPercentage", newVatPercentage);
    this.dgOrderlines.instance.cellValue(rowIndex, "ItemPrice", itemPrice);
    this.dgOrderlines.instance.cellValue(rowIndex, "TotalPriceIncl", totalPriceIncl);
    this.dgOrderlines.instance.cellValue(rowIndex, "TotalPriceExcl", totalPriceExcl);
    this.dgOrderlines.instance.cellValue(rowIndex, "TotalVat", totalVat);
    this.dgOrderlines.instance.cellValue(rowIndex, "Amount", amount);

    //Repaint the row
    this.dataGrid.instance.repaintRows(rowIndex);

    //Calc vat      
  }

  updateRowFeeAndRepaint(rowIndex: any, order: Order, product: Product, fee: Fee, amount: number ) {
    if ((product != null) && (fee != null))
    {
      if (fee.CalculationId == 1) //Kaffestauer
      {
        if (order.ShippingCountryId == 62 && (!this.order.IsOrganization) ) { //Verstuurd naar duitsland //checkbox gaan kijken
          var totalFee = ((amount * product.Weight) / 1000) * fee.Value;
          this.dgOrderlines.instance.cellValue(rowIndex, "FeeValue", totalFee);
          this.dgOrderlines.instance.cellValue(rowIndex, "FeeOid", fee.Oid);          
          this.dgOrderlines.instance.cellValue(rowIndex, "FeeName", fee.Name + " ( € " + fee.Value + " )");
        } else {
          this.dgOrderlines.instance.cellValue(rowIndex, "FeeOid", 0);  
          this.dgOrderlines.instance.cellValue(rowIndex, "FeeValue", 0);
          this.dgOrderlines.instance.cellValue(rowIndex, "FeeName", "");
        }

      }
      //Repaint the row
      this.dataGrid.instance.repaintRows(rowIndex);  
    }
  }
  
  updateRowWeightAndRepaint(rowIndex: any, product: Product, amount: number ) {
    if (product != null)
    {
        //Update values inside row
        var totalWeight = (amount * product.Weight);
        this.dgOrderlines.instance.cellValue(rowIndex, "TotalWeight", totalWeight);
      //Repaint the row
      this.dataGrid.instance.repaintRows(rowIndex);    
    }
  }

  ShippingCostVatPercentageChanged(e: any) {
    if(!this.DisableCalc){
    this.order.ShippingCostExclVat = parseFloat(this.convertToExclVat(this.order.ShippingCostInclVat, parseFloat(e.value)).toFixed(2));
    this.order.ShippingCostVat = parseFloat(this.calcVat(this.order.ShippingCostInclVat, this.order.ShippingCostExclVat).toFixed(2));
    this.calcTotal();
    }
  }

  SubTotalPriceInclVatChanged(e: any) {
    if(!this.DisableCalc){
    this.calcTotal();
    }
  }

  chkDisableCalcChanged()
  {
    if (!this.loadIndicatorVisible)
      this.DisableCalc = !this.DisableCalc;
  }

  DiscountChanged(e: any) {
    if(!this.DisableCalc){
    var discount: number = 0;
    if (e.value != null) discount = e.value;
    this.order.DiscountInclVat = Math.abs(parseFloat(discount.toFixed(2)));
    this.order.DiscountExclVat = Math.abs(parseFloat(this.convertToExclVat(discount, this.order.ShippingCostVatPercentage).toFixed(2)));
    this.order.DiscountVat = parseFloat(this.calcVat(this.order.DiscountInclVat, this.order.DiscountExclVat).toFixed(2));
    this.calcTotal();
    }
  }

  ShippingCostInclVatChanged(e: any) {
    if(!this.DisableCalc){
    this.order.ShippingCostInclVat = parseFloat(e.value.toFixed(2));
    this.order.ShippingCostExclVat = parseFloat(this.convertToExclVat(parseFloat(e.value), this.order.ShippingCostVatPercentage).toFixed(2));
    this.order.ShippingCostVat = parseFloat(this.calcVat(this.order.ShippingCostInclVat, this.order.ShippingCostExclVat).toFixed(2));
    this.calcTotal();
    }
  }

  SubTotalVatChanged(e: any) {
    if(!this.DisableCalc){
    this.calcTotal();
    }
  }

  FeeVatPercentageChanged()
  {
    if(!this.DisableCalc){
    this.order.FeeTotalPercentage = this.order.FeeTotalPercentage == 0 ? this.platformAccount.DefaultVatProductPercentage : this.order.FeeTotalPercentage
    this.order.FeeTotalExcl =  parseFloat((this.convertToInclVat(this.order.TotalFee, this.order.FeeTotalPercentage)).toFixed(2)); //Inclusief aanpassen in db zodat het goed komt toekomst 
    this.order.FeeVat =  parseFloat((this.order.FeeTotalExcl - this.order.TotalFee).toFixed(2));
    this.calcTotal();
    }
  }

  convertToExclVat(inclPrice: number, vatPercentage: number) {
    var basenumber = 100 + vatPercentage;
    return (100 / basenumber) * inclPrice;
  }

  convertToInclVat(exclPrice: number, vatPercentage: number) {
    var basenumber = (vatPercentage / 100) + 1;
    return exclPrice * basenumber;
  }

  calcVat(inclPrice: number, exclPrice: number) {
    return inclPrice - exclPrice;
  }

  calcTotal() {
    try {      
      if(!this.DisableCalc){
        //Calc discount on subtotal
        if (this.platformAccount.AddFee == false)
        {
          this.order.TotalPriceExclVat = parseFloat(((this.order.SubTotalPriceExclVat - this.order.DiscountExclVat) + this.order.ShippingCostExclVat - this.order.FeeVat).toFixed(2));
          this.order.TotalPriceInclVat =  parseFloat(((this.order.SubTotalPriceInclVat - this.order.DiscountInclVat) + this.order.ShippingCostInclVat).toFixed(2));
          this.order.TotalVat = parseFloat(this.calcVat(this.order.TotalPriceInclVat, this.order.TotalPriceExclVat).toFixed(2));
        }else{
          this.order.TotalPriceExclVat = parseFloat(((this.order.SubTotalPriceExclVat - this.order.DiscountExclVat) + this.order.ShippingCostExclVat + this.order.TotalFee).toFixed(2));
          this.order.TotalPriceInclVat =  parseFloat(((this.order.SubTotalPriceInclVat - this.order.DiscountInclVat) + this.order.ShippingCostInclVat + (this.order.TotalFee + this.order.FeeVat)).toFixed(2));
          this.order.TotalVat = parseFloat((this.calcVat(this.order.TotalPriceInclVat, this.order.TotalPriceExclVat)).toFixed(2));
        }

        this.isOrderSaved = false;
      }
    }
    catch(e) {

    }
  }


  CopyToClipboard(copy: any){
    if(typeof copy === 'object')
      this.clipboardService.copyFromContent(copy.element.textContent);
    else
      this.clipboardService.copyFromContent(copy);
  }

  deleteOrder(){
    if (this.order.InvoiceNumber == 0)
    {
      var result = confirm(this.translateService.instant('orders.delete.hint.body'), this.translateService.instant('orders.delte.hint.title'));  
      result.then((dialogResult) => {
        if (dialogResult) {
          this.orderService.DeleteOrder(this.order)
          .toPromise()
          .then(result => {
              this.alert.success(this.translateService.instant('datasourceDatagridService.delete'));
              this.popupVisible = false;
          return result;
          });
        }
      });
    } else { 
      var result = confirm(this.translateService.instant('orders.deletecredit.hint.body'), this.translateService.instant('orders.deletecredit.hint.title'));  
      result.then((dialogResult) => {
        if (dialogResult) {
          this.createCredit();
        }
      });
    }
  }


  //Coppy values
  CopyOrderToBilling() {
    this.order.BillingCompany = this.order.OrderCompany;
    this.order.BillingCountry = this.order.OrderCountry;
    this.order.BillingStreet = this.order.OrderStreet;
    this.order.BillingAddress1 = this.order.OrderAddress1;
    this.order.BillingAddress2 = this.order.OrderAddress2;
    this.order.BillingHouseNumberExt = this.order.OrderHouseNumberExt;
    this.order.BillingCity = this.order.OrderCity;
    this.order.BillingHouseNumber = this.order.OrderHouseNumber;
    this.order.BillingAddressSupplement = this.order.OrderAddressSupplement;
    this.order.BillingZipCode = this.order.OrderZipCode;
    this.order.BillingFirstName = this.order.OrderFirstName;
    this.order.BillingLastName = this.order.OrderLastName;
    this.order.BillingEmail = this.order.OrderEmail;
    this.order.BillingMobile = this.order.OrderMobile;
    this.order.BillingPhone = this.order.OrderPhone;
    this.order.BillingCountryId = this.order.OrderCountryId;
  }

  CopyBillingToOrder() {
    this.order.OrderCompany = this.order.BillingCompany;
    this.order.OrderCountry = this.order.BillingCountry;
    this.order.OrderStreet = this.order.BillingStreet;
    this.order.OrderAddress1 = this.order.BillingAddress1;
    this.order.OrderAddress2 = this.order.BillingAddress2;
    this.order.OrderHouseNumberExt = this.order.BillingHouseNumberExt;
    this.order.OrderCity = this.order.BillingCity;
    this.order.OrderHouseNumber = this.order.BillingHouseNumber;
    this.order.OrderAddressSupplement = this.order.BillingAddressSupplement;
    this.order.OrderZipCode = this.order.BillingZipCode;
    this.order.OrderFirstName = this.order.BillingFirstName;
    this.order.OrderLastName = this.order.BillingLastName;
    this.order.OrderEmail = this.order.BillingEmail;
    this.order.OrderMobile = this.order.BillingMobile;
    this.order.OrderPhone = this.order.BillingPhone;
    this.order.OrderCountryId = this.order.BillingCountryId;
  }

  CopyShippingToBilling() {
    this.order.BillingCompany = this.order.ShippingCompany;
    this.order.BillingCountry = this.order.ShippingCountry;
    this.order.BillingStreet = this.order.ShippingStreet;
    this.order.BillingAddress1 = this.order.ShippingAddress1;
    this.order.BillingAddress2 = this.order.ShippingAddress2;
    this.order.BillingHouseNumberExt = this.order.ShippingHouseNumberExt;
    this.order.BillingCity = this.order.ShippingCity;
    this.order.BillingHouseNumber = this.order.ShippingHouseNumber;
    this.order.BillingAddressSupplement = this.order.ShippingAddressSupplement;
    this.order.BillingZipCode = this.order.ShippingZipCode;
    this.order.BillingFirstName = this.order.ShippingFirstName;
    this.order.BillingLastName = this.order.ShippingLastName;
    this.order.BillingEmail = this.order.ShippingEmail;
    this.order.BillingMobile = this.order.ShippingMobile;
    this.order.BillingPhone = this.order.ShippingPhone;
    this.order.BillingCountryId = this.order.ShippingCountryId;
  }

  CopyBillingToShipping() {
    this.order.ShippingCompany = this.order.BillingCompany;
    this.order.ShippingCountry = this.order.BillingCountry;
    this.order.ShippingStreet = this.order.BillingStreet;
    this.order.ShippingAddress1 = this.order.BillingAddress1;
    this.order.ShippingAddress2 = this.order.BillingAddress2;
    this.order.ShippingHouseNumberExt = this.order.BillingHouseNumberExt;
    this.order.ShippingCity = this.order.BillingCity;
    this.order.ShippingHouseNumber = this.order.BillingHouseNumber;
    this.order.ShippingAddressSupplement = this.order.BillingAddressSupplement;
    this.order.ShippingZipCode = this.order.BillingZipCode;
    this.order.ShippingFirstName = this.order.BillingFirstName;
    this.order.ShippingLastName = this.order.BillingLastName;
    this.order.ShippingEmail = this.order.BillingEmail;
    this.order.ShippingMobile = this.order.BillingMobile;
    this.order.ShippingPhone = this.order.BillingPhone;
    this.order.ShippingCountryId = this.order.BillingCountryId;
  }

  customizeText(e) {
    return e.value + "%";
  };

  getInvoice() {
 //   if(this.order.InvoiceNumber  != 0){
      this.loadIndicatorVisible = true;
      this.orderService.downloadInvoice(this.order.Oid).subscribe(
      (response) => {
          var blob = new Blob([response], { type: 'application/pdf' });
          saveAs(blob, this.order.InvoiceNumber +'.pdf');
          this.loadIndicatorVisible = false;
          this.changeDetectorRef.detectChanges()
      },
      e => {
            console.log(e); 
            this.loadIndicatorVisible = false;
            this.changeDetectorRef.detectChanges()
      });
   // }else{
   //   this.alert.warning(this.translateService.instant('orders.printinvoicenoinvocienumber.hint.body'));
  //  }
  }

  createInvoice()
  {
    this.orderService.CreateInvoice(this.order.Oid)
          .toPromise()
          .then(result => {
              var message = _('order.createInvoice');
              this.translateService.get(message).subscribe(res => {
              this.alert.success(this.translateService.instant('orders.succescreateinvoice.hint.body'));
              });
              this.order = result;
          });
  }

  sendInvoice()
  {
    if(this.order.InvoiceNumber  != 0){
      this.loadIndicatorVisible = true;
      this.orderService.SendInvoice(this.order.Oid)
      .toPromise()
      .then(result => {
          var message = _('order.sendInvoice');
          this.translateService.get(message).subscribe(res => {
          this.alert.success(this.translateService.instant('orders.sendinvoicenoinvocienumber.succes.body'));
          this.loadIndicatorVisible = false;
          });
          this.order = result;
        })
        .catch(err => {
            console.log(err); 
            this.loadIndicatorVisible = false;
            this.changeDetectorRef.detectChanges()
          });
    }else{
      this.alert.warning(this.translateService.instant('orders.printinvoicenoinvocienumber.hint.body'));
    }
  }

  copyThisOrder()
  {
    var result = confirm(this.translateService.instant('orders.copy.hint.body'), this.translateService.instant('orders.copy.hint.title'));  
    result.then((dialogResult) => {
      if (dialogResult) {        
        this.orderService.CopyOrder(this.order)
          .toPromise()
          .then(result => {
              var message = _('datasourceDatagridService.successfullyInsert');
              this.translateService.get(message).subscribe(res => {
              this.alert.success(this.translateService.instant('orders.copy.succes.message'));
              });
              this.order = result;
          });
        }      
    });     
  }


  createCredit()
  {
    if (this.order.InvoiceNumber != 0)
    {
      var result = confirm(this.translateService.instant('orders.createinvoice.hint.body'), this.translateService.instant('orders.createinvoice.hint.title'));  
      result.then((dialogResult) => {
        if (dialogResult) {
          this.orderService.Credit(this.order)
          .toPromise()
          .then(result => {
              var message = _('datasourceDatagridService.successfullyInsert');
              this.translateService.get(message).subscribe(res => {
                this.alert.success(this.translateService.instant('orders.createinvoice.succes.message'));
              });
              this.order = result;
              this.disableStatusButtons();
          });
        }
      });
    } else { 
      var result = confirm(this.translateService.instant('orders.creditdeleteorder.hint.body'), this.translateService.instant('orders.creditdeleteorder.hint.title'));  
      result.then((dialogResult) => {
        if (dialogResult) {
          this.deleteOrder();
        }
      });
    }   
  }

  
  onOrderOptionClick(e:any)
  {
    switch(e.itemData.value) { 
      case 1: { 
        this.toggleOrderDetails();
         break; 
      } 
      case 2: { 
         this.copyThisOrder();
         break; 
      } 
      case 3: { 
        this.getInvoice();
        break; 
     } 
     case 4: { 
      this.createCredit();
      break; 
   
     } 
     case 5: { 
      this.deleteOrder();
      break; 
     }
     case 6: { 
      this.createInvoice();
      break; 
   
     }
     case 7: { 
      this.sendInvoice();
      break; 
   
     }
    } 
  }

  ngOnInit() {
    this.authenticationService.getOrganisationObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.dataSource = this.datasourceDatagridService.getDatasource("Order");
        this.countryStores = this.datasourceLookupService.getDatasource("Country");
        this.productStores = this.datasourceLookupService.getDatasource("Product");
        this.carrierSelectionStores = this.datasourceLookupService.getDatasource("CarrierSelection");
      })
      this.countryStores = this.datasourceLookupService.getDatasource("Country");
      this.dataSource = this.datasourceDatagridService.getDatasource("Order");
      this.languageStores = this.datasourceLookupService.getDatasource("Language");
      this.productStores = this.datasourceLookupService.getDatasource("Product");
      this.carrierSelectionStores = this.datasourceLookupService.getDatasource("CarrierSelection");

      this.orderSettings = [
        { value: 1, name: "Order view", icon: "user" },
        { value: 2, name: "Copy order", icon: "fal fa-copy" },
        { value: 6, name: "Create invoice", icon: "fas fa-file-invoice-dollar" },
        { value: 3, name: "Download invoice", icon: "group" },
        { value: 7, name: "Email invoice", icon: "email" },
        { value: 4, name: "Create credit", icon: "runner" },
        { value: 5, name: "Delete", icon: "trash" }];
  }

  ngAfterViewChecked()
{
  this.changeDetectorRef.detectChanges();
}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed.next();
    this.destroyed.complete();
  }

  
  
}

export class SimpleObject {
  value: number | string;
  name: string;
  icon?: string;
  badge?: string;
}


