import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient } from "@angular/common/http";
import { AlertService} from '@/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DatasourceDatagridDetailService {

  constructor(private httpClient: HttpClient, private alert: AlertService, private translate: TranslateService) {}

  detailStores = {};  //Is required for caching detail stores.
  //This will help you avoid getting the infinite loop
  //when Angular will call getOrderItems for comparing the current and previous 
  //values of the dataSource option of your detail grid.


  public getDetailItems(url: string, masterKey: any) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== "";
    }

    if (!this.detailStores.hasOwnProperty(masterKey)) {
      const apiUrl = environment.apiUrl + url + "/details";
      return new CustomStore({
        key: "Oid",        
        load: (loadOptions) => {
          return this.httpClient.get(apiUrl + '/GetDetails/' + encodeURIComponent(masterKey))
            .toPromise()
            .then(result => {
              return result;
            })
            .catch(err => {
              console.log(err.error);
              var message = _('datasourceDatagridService.loadError');
              this.translate.get(message).subscribe(res => { this.alert.error(res) });
              return Promise.reject();
         });
        }, // ~load
        byKey: (key) => {
          return this.httpClient.get(apiUrl + '/GetDetails/' + encodeURIComponent(key))
            .toPromise();
        }, 
        update: (key, values) => {
          console.log(masterKey);
          return this.httpClient.put(apiUrl + '/PutDetail/' + encodeURIComponent(key), values)
            .toPromise()
            .then(result => {
              var message = _('datasourceDatagridService.successfullyUpdated');
              this.translate.get(message).subscribe(res => { this.alert.success(res) });
              return result;
            })
            .catch(err => {
               console.log(err.error);
               var message = _('datasourceDatagridService.updateError');
               this.translate.get(message).subscribe(res => { this.alert.error(res) });
               return Promise.reject();
          });
        }, //~insert
        remove: (key) => {
          return this.httpClient.delete(apiUrl + '/DeleteDetail/' + encodeURIComponent(key))
          .toPromise()
          .then(result => {
            var message = _('datasourceDatagridService.successfullyDeleted');
            this.translate.get(message).subscribe(res => { this.alert.success(res) });
          })
          .catch(err => {
             console.log(err.error);
             var message = _('datasourceDatagridService.deleteError');
             this.translate.get(message).subscribe(res => { this.alert.error(res) });
             return Promise.reject();
        });
        }, //~remove
        insert: (values) => {
          return this.httpClient.post(apiUrl + '/PostDetail/', values)
          .toPromise()
          .then(result => {
            var message = _('datasourceDatagridService.successfullyInsert');
            this.translate.get(message).subscribe(res => { this.alert.success(res) });
            return result;
          })
          .catch(err => {
             console.log(err.error);
             var message = _('datasourceDatagridService.insertError');
             this.translate.get(message).subscribe(res => { this.alert.error(res) });
             return Promise.reject();
        });
        }, //~insert
        errorHandler: function(error) {
          var message = _('datasourceDatagridService.defaultError');
          this.translate.get(message).subscribe(res => { this.alert.error(res) });
          console.log(error.message);
        }
      });
    }
  }
}