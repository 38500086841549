import { ILanguage} from '@/interfaces'

export class Language implements ILanguage {

  constructor() {}

  Oid: number;
  Name: string;
  LangCode: string;
  CreateDate: Date;
  LastModifiedDate: Date;
  }