import { NgModule, Component, enableProdMode, Inject } from "@angular/core";
import { HttpClient, HttpClientModule, HttpParams } from "@angular/common/http";
import { DxDataGridModule } from "devextreme-angular";
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { environment } from "@environments/environment";
import { DatasourceDatagridService} from "@/services/";
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'platform-datagrid',
  templateUrl: './platform-datagrid.component.html'
  
})
export class platformDatagridComponent {
  dataSource: any = {};

  constructor(
    private datasourceDatagridService: DatasourceDatagridService,
    private translate: TranslateService) { }

  ngOnInit(){
    this.dataSource = this.datasourceDatagridService.getDatasource("Platform");
  }
}